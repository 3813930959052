import {React, useState, useEffect} from 'react';
import { AlertThree } from "master-react-lib";

import { serialize } from "./markdowntoreact.js";

import { getAnnouncements } from "../backend.js";

import icon from "../assets/imgs/leftIcon.svg";

function Annoucement() {
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const response = await getAnnouncements();

          let data = response?.data?.body?.data ?? [];

          data = data.filter(
            ({ status, publishTo, publishFrom }) =>
              status === "Public" &&
              new Date(publishTo) > new Date() &&
              new Date(publishFrom) < new Date()
          );

          let count = 0;

          let announcements = data.map(item => {
            let x =
              item.announcement?.description && JSON.parse(item.announcement.description);
            let editor;
            if (x) {
              editor = { children: [...x] };
            }

            const description =
              item.announcement?.description &&
              item.announcement.description?.includes('"type":')
                ? serialize(editor)
                : item.announcement?.description;


            return {
              title: `${++count}. ${item.announcement.title}`,
              description: description,
              path: item.announcement?.button
                ? item.announcement?.button?.url
                : "",
              target: "_blank",
              status: item.status,
              buttonLabel: item.announcement.button
                ? item.announcement.button.buttonLabel
                : "",
            };
            });

          setAnnouncements(announcements);

        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }, []);

    var tutorialSteps = [];
    
    const obj = [
        {
            title: "New moon of Shawwal sighted.1",
            description:
                "In friendship diminution instrument so. Son sure paid door with say them. Two among sir sorry court.",
            path: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
            target: "_blank",
            status: "Hidden",
            buttonLabel: "Yahoo",
        },

        {
            title: "New moon of Shawwal sighted.2",
            description:
                "In friendship diminution instrument so. Son sure paid door with say them. Two among sir sorry court.",
            path: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
            target: "_blank",
            status: "Public",
            buttonLabel: "Yahoo",
        },

        {
            title: "New moon of Shawwal sighted.3",
            description:
                "In friendship diminution instrument so. Son sure paid door with say them. Two among sir sorry court.",
            path: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
            target: "_blank",
            status: "Public",
            buttonLabel: "facebook",
        },

        {
            title: "New moon of Shawwal sighted.4",
            description:
                "In friendship diminution instrument so. Son sure paid door with say them. Two among sir sorry court.",
            path: "https://search.yahoo.com/search?fr=mcafee&type=E210US91213G0&p=google",
            target: "_blank",
            status: "Public",
            buttonLabel: "Youtube",
        },
    ];
    obj.map((element) => {
        if (element.status === "Public") tutorialSteps.push(element);
    });


    return (
      (announcements.length > 0) ? (<AlertThree
        leftIcon={icon}
        tutorialSteps={announcements}
        background="#000"
        color="#fff"
        arrowColor="#4d4d4d"
        ButtonRadius="0"
        maxWidth="1170px"
        ButtonText="Read More"
        ButtonStatus={true}
        AlertDuration={8000000}
      />) : (<></>)
    );
}


export default Annoucement;