import { React, useState, useEffect } from "react";
import { Section, ImageGridOne } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import Lightbox from 'react-image-lightbox';
import Title from './Title';

import GalleryImg from '../assets/imgs/GalleryImg.png';
import AboutImg from '../assets/imgs/AboutImg.png';
import AboutBanner from '../assets/imgs/AboutBanner.jpg';

import { getPhotos } from "../backend.js";

function PhotoGallery(props) {

    const images = [GalleryImg, AboutImg, AboutBanner, AboutImg, AboutBanner, AboutBanner];

    const [isOpen, setisOpen] = useState(false)

    const [photoIndex, setphotoIndex] = useState(0)


    const imageClick = (i) => {
        setphotoIndex(i)
        setisOpen(true)
    }

    const [firstPhoto, setFirstPhoto] = useState(null);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const response = await getPhotos();

          let data = response?.data?.body?.data ?? [];

          // filter data Here..

          let photos = data.map((item) => {
            return {
              id: item._id,
              title: item.title,
              description: item.description,
              image: item.imageMetaData.imageUrl,
              imageName: item.imageMetaData.imageName,
            };
          });

          console.log("PHOTOS");
          console.log(photos);

          //setFirstPhoto(photos.shift());
          setPhotos(photos);

          //console.log(firstPhoto);
          
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }, []);

    return (
      <div className="FeaturedEvents">
        <Section
          background="#F5F7FC"
          //ContainerWidth=""
          Spacing="80px"
          Container={
            <ImageGridOne
              GalleryGrid={
                <div>
                  <Title
                    Title="Photo Gallery"
                    //ButtonStatus={true}
                    //ButtonLabel="ALL PHOTOS"
                    Link=""
                  />

                  <Grid container spacing={1} style={{ marginTop: "32px" }}>
                    <Grid item md={6} xs={12}>
                      <div style={{ height: "378px" }}>
                        <img src={images[0]} onClick={() => imageClick(1)} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <div style={{ height: "185px" }}>
                            <img
                              src={images[1]}
                              onClick={() => imageClick(2)}
                            />
                          </div>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <div style={{ height: "185px" }}>
                            <img
                              src={images[2]}
                              onClick={() => imageClick(3)}
                            />
                          </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <div style={{ height: "185px" }}>
                            <img
                              src={images[3]}
                              onClick={() => imageClick(4)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <div style={{ height: "185px" }}>
                            <img
                              src={images[4]}
                              onClick={() => imageClick(5)}
                            />
                          </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <div style={{ height: "185px" }}>
                            <img
                              src={images[5]}
                              onClick={() => imageClick(6)}
                            />
                          </div>
                        </Grid>

                        {/* {photos.map((item, index) => {
                          return (
                            <Grid item md={12} xs={12}>
                              <div
                                style={{
                                  height: "185px",
                                }}
                              >
                                <img
                                  src={item.image}
                                  alt={item.imageName}
                                  onClick={() => imageClick(index + 7)}
                                />
                              </div>
                            </Grid>
                          );
                        })} */}

                      </Grid>
                    </Grid>
                  </Grid>

                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setisOpen(false)}
                      onMovePrevRequest={() =>
                        setphotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setphotoIndex((photoIndex + 1) % images.length)
                      }
                      animationDisabled={false}
                      animationOnKeyInput={true}
                    />
                  )}
                </div>
              }
            />
          }
        />
      </div>
    );
}


export default PhotoGallery;