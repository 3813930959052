import { React, useState, useEffect } from "react";
import { Section, CardSeven, PageTitleTwo, ButtonOne } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import EventImg from "../assets/imgs/EventImg.png";
import backgroundPattern from "../assets/imgs/PageHeaderBG.svg";
import ADS from "./ADS";
import CtaOneCardDark from "./CtaOneCardDark";
import Button from "@material-ui/core/Button";
import Title from "./Title";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import ImageOne from "../assets/imgs/events/web-service-event.jpg";
import ImageTwo from "../assets/imgs/events/events-1-1170x658.jpg";
import ImageThree from "../assets/imgs/events/events-4-1170x658.jpg";
import ImageFour from "../assets/imgs/events/events-6-1170x658.jpg";

import { getEvents } from "../backend.js";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function ParseDescription(description) {
  let x = description && JSON.parse(description);

  description = x[0]?.children[0]?.text ?? "";

  if (description.length > 200) {
    description = `${description.substring(0, 200)}...`;
  }

  return description;
}

function EventsListing() {
  const breadcrumbsData = [{ page: "HOME", paht: "/" }];
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getEvents();

        let data = response?.data?.body?.data ?? [];

        let events = data.filter(
          ({ status, event }) =>
            status === "Public" &&
            new Date(event.eventStart.startAt) >= new Date()
        );

        let pastEvents = data.filter(
          ({ status, event }) =>
            status === "Public" &&
            new Date(event.eventStart.startAt) < new Date()
        );

        events = events.map((item) => {
          let startDate = moment(item.event.eventStart.startAt)
            .utcOffset(0)
            .format("MMMM Do YYYY");

          let startTime = moment(item.event.eventStart.startTime)
            .utcOffset(0)
            .format("h:mm a");

          let endTime = moment(item.event.endAt).utcOffset(0).format("h:mm a");

          return {
            id: item._id,
            image: item.event.imageDetails.imageUrl,
            imageAlt: item.event.imageDetails.imageName,
            title: item.event.title,
            time: `${startDate} @ ${startTime} - ${endTime}`,
            subTitle: ParseDescription(item.event.description),
            buttonText: "Read More",
            link: `/events-details\\${item._id}`,
          };
        });

        pastEvents = pastEvents.map((item) => {
          const momnetStartDate = moment(
            item.event.eventStart.startAt
          ).utcOffset(0);

          let startDate = momnetStartDate.format("MMMM Do YYYY");

          let startTime = moment(item.event.eventStart.startTime)
            .utcOffset(0)
            .format("h:mm a");

          let endTime = moment(item.event.endAt).utcOffset(0).format("h:mm a");

          return {
            id: item._id,
            image: item.event.imageDetails.imageUrl,
            imageAlt: item.event.imageDetails.imageName,
            title: item.event.title,
            time: `${startDate} @ ${startTime} - ${endTime}`,
            subTitle: ParseDescription(item.event.description),
            buttonText: "Read More",
            link: `/events-details\\${item._id}`,
            month: momnetStartDate.format("MMM"),
            day: momnetStartDate.format("D"),
          };
        });

        if (events.length === 0 && 
          pastEvents.length >= 2)
        {
          events.push(pastEvents.shift());
          events.push(pastEvents.shift());
        }

        setEvents(events);
        setPastEvents(pastEvents);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    <div className="FeaturedEvents EventListng">
      <PageTitleTwo
        Title="Events"
        color="#B9278A"
        backgroundcolor="#F5F7FC"
        backgroundPattern={backgroundPattern}
        height="auto"
        breadcrumbsData={breadcrumbsData}
        currentPage="Events"
      />
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <div>
            <Grid container spacing={3}>
              <Grid item md={9} xs={12}>
                <Grid container spacing={3}>
                  {events.map((item, index) => (
                    <Grid key={item.id} item md={12} xs={12}>
                      <CardSeven
                        image={item.image}
                        title={item.title}
                        time={item.time}
                        subTitle={item.subTitle}
                        buttonText={item.buttonText}
                        link={item.link}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item md={3} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <CtaOneCardDark />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <ADS />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />

            <Grid container spacing={0}>
              <Grid item md={12} xs={12} style={{ marginBottom: "32px" }}>
                <Title Title="Past Events" />
              </Grid>

              {pastEvents.map((item, index) => (
                <Grid key={item.id} item md={6} xs={12}>
                  <div className="PastEvents">
                    <div className="Past_left">
                      <div>
                        <span>{item.month}</span>
                        <h4>{item.day}</h4>
                      </div>
                    </div>
                    <div className="Past_Right">
                      <div className="DtlDate">{item.time}</div>
                      <h2>{item.title}</h2>
                      <Button href={`/events-details\\${item.id}`}>
                        EVENT DETAILS <ArrowForwardRoundedIcon />
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))}

              {/* <Grid item md={6} xs={12}>
                <div className="PastEvents">
                  <div className="Past_left">
                    <div>
                      <span>MAY</span>
                      <h4>31</h4>
                    </div>
                  </div>
                  <div className="Past_Right">
                    <div className="DtlDate">
                      May 31, 2023 @ 9:50 pm - 10:00 pm
                    </div>
                    <h2>Lecture On Prophets Salah</h2>
                    <Button href='/events-details'>EVENT DETAILS <ArrowForwardRoundedIcon /></Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="PastEvents">
                  <div className="Past_left">
                    <div>
                      <span>JUN</span>
                      <h4>01</h4>
                    </div>
                  </div>
                  <div className="Past_Right">
                    <div className="DtlDate">
                      June 1, 2023 @ 8:00 pm - 10:00 pm
                    </div>
                    <h2>Tarawih Prayers</h2>
                    <Button href='/events-details'>EVENT DETAILS <ArrowForwardRoundedIcon /></Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="PastEvents">
                  <div className="Past_left">
                    <div>
                      <span>JUN</span>
                      <h4>03</h4>
                    </div>
                  </div>
                  <div className="Past_Right">
                    <div className="DtlDate">
                      June 3, 2023 @ 9:45 am - 11:30 pm
                    </div>
                    <h2>Food Pantry</h2>
                    <Button href='/events-details'>EVENT DETAILS <ArrowForwardRoundedIcon /></Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="PastEvents">
                  <div className="Past_left">
                    <div>
                      <span>JUN</span>
                      <h4>04</h4>
                    </div>
                  </div>
                  <div className="Past_Right">
                    <div className="DtlDate">
                      March 3, 2021 @ 8:00 am - 5:00 pm
                    </div>
                    <h2>web service</h2>
                    <Button href='/events-details'>EVENT DETAILS <ArrowForwardRoundedIcon /></Button>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="PastEvents">
                  <div className="Past_left">
                    <div>
                      <span>JUN</span>
                      <h4>09</h4>
                    </div>
                  </div>
                  <div className="Past_Right">
                    <div className="DtlDate">
                      June 9, 2021 @ 1:00 pm - 2:00 pm
                    </div>
                    <h2>Friday Prayer</h2>
                    <Button href='/events-details'>EVENT DETAILS <ArrowForwardRoundedIcon /></Button>
                  </div>
                </div>
              </Grid> */}

              {/* <Grid item md={12} xs={12}>
                                <ButtonOne
                                    label="LOAD MORE"
                                    link=""
                                    target="_blank"

                                    arrowIcon={<ExpandMoreRoundedIcon />}
                                    arrowStatus={true}
                                    arrowColor="#DB9E30"

                                    buttoncolor="#F5F8FC"
                                    width="100%"
                                    height="50px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    color="#DB9E30"
                                    fontWeight="600"
                                    textAlign="center"
                                />
                            </Grid> */}
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default EventsListing;