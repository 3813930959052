import React from 'react';
import { TitleOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';


function Title(props) {




    return (
        <TitleOne
            TitleBold={props.Title}

            //lineColor="#0053A5"
            TitleBoldColor="#0B132B"

            Button={props.ButtonStatus}
            buttonLebel={props.ButtonLabel}
            Link={props.Link}
            //target="blank"

            arrowIcon={<ArrowForwardRoundedIcon />}
            arrowStatus={true}
            arrowColor="#fff"

            buttoncolor="#DB9E30"
            width="auto"
            height="48px"
            border="#DB9E30 1px solid"
            borderRadius="4px"
            colorlabel="#fff"
            fontWeight="600"
            textAlign="center"

        />
    );
}


export default Title;