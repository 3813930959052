import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import Grid from '@material-ui/core/Grid';
import { SocialMediaOne, Section, ButtonOne } from "master-react-lib";
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'


import Banner from '../../assets/imgs/reads/read-img-three.jpeg';



function SocialSection(props) {

    const click = () => {
        console.log("Back Button Clicked");
    };

    const socialIconData = [

        { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-facebook-icon.webp", link: "https://www.facebook.com/theAbrarNYC/" },
        // { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-twitter-icon.webp", link: "https://www.facebook.com/" },
         { icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-youtube-icon.webp", link: "https://www.youtube.com/channel/UCW31JSvDCszmIMcMvsiIKng/channels" },

    ];

    return (

        <div className="Socialsection">
            <div>
                <Button className="backBtn" href="/reads">
                    <ArrowBackRoundedIcon /> All Reads
                </Button></div>
            <div>
                <SocialMediaOne
                    copyBackground="#DB9E30"
                    copyColor="#fff"
                    socialIconData={socialIconData}
                    copyButton={false}
                    onClick={click}

                />
            </div>
        </div>

    );
}


export default SocialSection;