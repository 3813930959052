import React, { createRef, useState, useEffect } from 'react'
import { Section } from "master-react-lib";
import Grid from '@material-ui/core/Grid';

import BannerImg from '../assets/imgs/AboutBanner.jpg';

import BoardOfDirectors from './BoardOfDirectors';
import ServicesCommunity from './ServicesCommunity';
import ContactUs from './ContactUs';

import PageTitle from "./PageTitle";


function AboutUsSection() {




    const aboutRef = createRef();
    const missionRef = createRef();
    const boardofdirectorsRef = createRef();
    const ourservicesRef = createRef();
    const contactusRef = createRef();

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf("/about-us") > -1) {
                aboutRef && aboutRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/mission") > -1) {
                missionRef && missionRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else if (window.location.href.indexOf("/board-of-directors") > -1) {
                boardofdirectorsRef && boardofdirectorsRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else if (window.location.href.indexOf("/our-services") > -1) {
                ourservicesRef && ourservicesRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else if (window.location.href.indexOf("/contact-us") > -1) {
                contactusRef && contactusRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
            }
        }, 1000);

    }, [aboutRef, missionRef, boardofdirectorsRef, ourservicesRef]);



    return (
        <div className='FeaturedEvents' ref={aboutRef}>
            <PageTitle
                Title="About TheAbrar"
                Path="About TheAbrar"
            />
            <Section
                background="#fff"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <Grid container spacing={3}>


                        <Grid item md={12} xs={12}>
                            <h1 className="InnerPageTitle">Welcome to The Abrar Masjid</h1>
                        </Grid>

                        <Grid item md={8} xs={12}>
                            <div className="AboutBanner">
                                <img src={BannerImg} />
                            </div>

                            <div className="AboutContents">
                                <h1>About Us</h1>

                                <p>TheAbrar Inc. was founded in 2000, by Mr. Shahid Kaiser and other members. It was originally known as Riverdale Islamic Center.
                                    Prayers at that time were being held in a basement apartment. Around 2011, the building was under new management and we were forced to relocate.
                                    TheAbrar was conducting Jumah prayers at a local church and actively looking for a place we can call our own.</p>

                                <h2 ref={missionRef}>Our Mission</h2>
                                <p>Our mission is, “To provide multifaceted services to the Muslim community of Brooklyn
                                    and beyond that will fulfill their religious, social, cultural, educational, and recreatinal
                                    needs through building and maintaining appropriate facilities.”</p>

                                <h2>Our Vision</h2>
                                <p>Our mission is, “To provide multifaceted services to the Muslim community of Brooklyn and beyond that
                                    will fulfill their religious, social, cultural, educational, and recreatinal needs
                                    through building and maintaining appropriate facilities.”</p>



                                <div className="AboutSections" ref={boardofdirectorsRef}>
                                    <h1>Board Of Directors</h1>
                                    <BoardOfDirectors />
                                </div>

                                <div className="AboutSections" ref={ourservicesRef}>
                                    <h1>Services For The Community</h1>
                                    <ServicesCommunity />
                                </div>

                                <div className="AboutSections" ref={contactusRef}>
                                    <h1>Contact Us</h1>
                                    <ContactUs />
                                </div>

                            </div>

                        </Grid>

                        <Grid item md={4} xs={12}>
                            <div className="QuickLinkPannel">
                                <h2>Quicklinks</h2>
                                <li><a href="/about-us">About us</a></li>
                                <li><a href="/mission">Our mission & vision</a></li>
                                <li><a href="/board-of-directors">Board of directors</a></li>
                                <li><a href="/our-services">Our services</a></li>
                                <li><a href="/contact-us">Contact us</a></li>
                            </div>
                        </Grid>

                    </Grid>
                }
            />

        </div>
    );
}



export default AboutUsSection;