import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import SupportUs from '../SupportUs'
import FeaturedServices from '../FeaturedServices'
import FaqSection from '../FaqSection'



import '../../assets/css/style.css'
import '../../assets/css/responsive.css'




function FaqPage(props) {



  return (

    <div>
      <Header />
      <NavBar />
      <FaqSection />
      <Footer />
    </div>

  );
}


export default FaqPage;