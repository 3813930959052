import { React, useState, useEffect } from "react";
import { Section, VideoCardOne, VideoPlayerModal } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import ADS from "./ADS";

import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";

import { getVideos } from "../backend.js";

import ImageOne from "../assets/imgs/videos/sddefault.jpg";
import ImageTwo from "../assets/imgs/videos/video-two.jpg";
import ImageThree from "../assets/imgs/videos/video-three.jpg";

function YouTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

function FeaturedVideos() {
  const [isOpen, setOpen] = useState(false);
  const [isOpen_2, setOpen_2] = useState(false);
  const [isOpen_3, setOpen_3] = useState(false);

  const [videos, setVideos] = useState([]);


  const [modalVideoid, setmodalVideoId] = useState();

  function openVideoModal(e, videoId) {
    setmodalVideoId(videoId);
    e.preventDefault()
    setOpen(true);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getVideos();

        let data = response?.data?.body?.data ?? [];

        data = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" &&
            featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        data = data.sort((a, b) => a.video.order - b.video.order);

        let videos = data.map((item) => {
          const videoID = YouTubeGetID(item.video.link);
          return {
            id: item._id,
            videoID: videoID,
            image: videoID !== "" ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg` : ImageOne,
            title: item.video.title,
            link: item.video.link,
          };
        });

        setVideos(videos);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    videos.length > 0 ? (<div className="FeaturedEvents">

      <Section
        background="#F5F7FC"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Title
                Title="Featured Videos"
                ButtonStatus={true}
                ButtonLabel="ALL VIDEOS"
                Link="/videos"
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <div className="ScrollHr">
                <Grid container spacing={3}>

                  {videos.map((item, index) => (
                    <Grid
                      key={item._id}
                      item
                      md={4}
                      xs={4}
                      onClick={(e) => openVideoModal(e, item.videoID)}
                    >
                      <VideoCardOne
                        img={item.image}
                        title={item.title}
                        titleColor="#0A2540"
                        border="border"
                        icon={<SubscriptionsIcon />}
                        ImgHeight="196px"
                      />
                    </Grid>
                  ))}

                </Grid>
              </div>

            </Grid>

            {/* <Grid item md={4} xs={12} onClick={() => setOpen(true)}>
              <VideoCardOne
                img={ImageOne}
                title="TheAbrar Masjid needs you !"
                link="#"
                titleColor="#0A2540"
                border="border"
                icon={<SubscriptionsIcon />}
                ImgHeight="196px"
              />
            </Grid>

            <Grid item md={4} xs={12} onClick={() => setOpen_2(true)}>
              <VideoCardOne
                img={ImageTwo}
                title="TheAbrar masjid : a 4 years old muslim girl is confused between Mosque and Church ! PLEASE HELP"
                link="#"
                titleColor="#0A2540"
                border="border"
                icon={<SubscriptionsIcon />}
                ImgHeight="196px"
              />
            </Grid>

            <Grid item md={4} xs={12} onClick={() => setOpen_3(true)}>
              <VideoCardOne
                img={ImageThree}
                title="TheAbrar Ramadan Children Celebration / Saturday May 12, 2018"
                link="#"
                titleColor="#0A2540"
                border="border"
                icon={<SubscriptionsIcon />}
                ImgHeight="196px"
              />
            </Grid> */}
          </Grid>
        }
      />

      {/* <VideoPlayerModal
        vidoeID="V87FCuQhUcQ"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      /> */}

      <VideoPlayerModal
        vidoeID={modalVideoid}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />

    </div>) : <></>
  );
}

export default FeaturedVideos;
