import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import Resource from '../Resource'




import '../../assets/css/style.css'
import '../../assets/css/responsive.css'




function Dawah(props) {



  return (

    <div>
      <Header />
      <NavBar />
      <Resource />
      <Footer />
    </div>

  );
}


export default Dawah;