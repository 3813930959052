import { React, useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import BannerOne from "../assets/imgs/banners/the-abrar-banner-image.jpg";
import BannerImgTwo from "../assets/imgs/banner.png";
import { Button } from "@material-ui/core/";
import "../assets/css/BannerTwo.css";

import { getSliders } from "../backend.js";

const descriptionLength = 200;

function ParseDescription(description) {
  let x = description && JSON.parse(description);

  description = x[0]?.children[0]?.text ?? "";
  if (description.length > descriptionLength) {
    description = `${description.substring(0, descriptionLength)}...`;
  }

  return description;
}

function BannerTwo() {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getSliders();

        let data = response?.data?.body?.data ?? [];

        data = data.filter(
          ({ website }) =>
            website.status === "Public" &&
            new Date(website.publishTo) > new Date() &&
            new Date(website.publishFrom) < new Date()
        );

        let sliders = data.map((item) => {
          return {
            id: item._id,
            name: item.title.toUpperCase(),
            description: ParseDescription(item.description),
            img: item.imageMetaData.imageUrl,
            target: "_blank",
            link: item.button?.actionButton ? item.button.buttonLink : "#",
            showButton: item.button?.actionButton,
            buttonLabel: item.button?.actionButton
              ? item.button.buttonLabel
              : "",
          };
        });

        console.log(sliders);
        setSliders(sliders);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  // var items = [
  //   {
  //     img: BannerOne,
  //     name: "THE ABRAR MASJID OUR SECOND HOME",
  //     description: "Probably the most random thing you have ever seen!",
  //     link: "https://give.deenfund.com/theabrar",
  //   },
  //   {
  //     img: BannerImgTwo,
  //     name: '"AND ALLAH INVITES TO TH HOME OF PEACE"',
  //     description: "Hello World!",
  //     link: "https://give.deenfund.com/theabrar",
  //   },
  // ];

  return (
    <div className="BannerTowContainer">
      <Carousel
        className="BannerTwo"
        NextIcon={<ArrowForwardIosRoundedIcon />}
        PrevIcon={<ArrowBackIosRoundedIcon />}
        duration={2000}
        swipe={true}
        autoPlay={true}
        interval={8000}
        animation="fade"
        indicators={false}
        navButtonsAlwaysVisible={true}
      >
        {sliders.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}

function Item(props) {
  return (
    <div>
      <div className="BannerAlfa"></div>
      <div className="BannerImg">
        <img src={props.item.img} alt="slider" />
      </div>
      <div className="BannerDetailsContainer">
        <div className="BannerDetails">
          <h2>{props.item.name}</h2>
          <p>{props.item.description}</p>

          {props.item.showButton && (
            <Button
              className="BannerButton"
              href={props.item.link}
              target="_blank"
            >
              {props.item.buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BannerTwo;
