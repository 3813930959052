import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import SupportUs from '../SupportUs'
import FeaturedServices from '../FeaturedServices'
import ReadsSection from '../ReadsSection'



import '../../assets/css/style.css'
import '../../assets/css/responsive.css'




function ReadsPage(props) {



  return (

    <div>
      <Header />
      <NavBar />
      <ReadsSection />
      <SupportUs />
      <FeaturedServices />
      <Footer />
    </div>

  );
}


export default ReadsPage;