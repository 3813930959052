import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import AboutUsSection from '../AboutUsSection';

import '../../assets/css/style.css'
import '../../assets/css/responsive.css'





function AboutUsPage(props) {
  
  return (

    <div>
      <Header />
      <NavBar />
      <AboutUsSection />
      <Footer />
    </div>

  );
}


export default AboutUsPage;