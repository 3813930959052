import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import Grid from '@material-ui/core/Grid';
import { SocialMediaOne, Section, ButtonOne } from "master-react-lib";
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import SocialSection from './SocialSection';

import ReadImgOne from '../../assets/imgs/reads/read-img-one.jpeg';




function TheCharacterofProphet(props) {


    return (

        <div>
            <Header />
            <NavBar />

            <div className='FeaturedEvents'>
                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="20px"
                    Container={
                        <>
                            <Grid container spacing={3}>

                                <Grid item md={12} xs={12}>
                                    <SocialSection />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div className="eventDetailsBanner">
                                        <img src={ReadImgOne} />
                                    </div>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3}>

                                <Grid item md={5} xs={12}>
                                    <div className="eventDetailsContetns">
                                        <h1>The Character of Prophet Muhammad ﷺ: The Proofs of Prophethood Series</h1>
                                    </div>
                                </Grid>

                                <Grid item md={7} xs={12}>
                                    <div className="eventDetailsContetns">
                                        <p><strong>The Character</strong> of The Prophet ﷺ God combined in His final Prophet ﷺ the most illustrious qualities, ensuring by that the confidence of his
                                            audience. His person sparkled from every angle and was thus seen as admirable in everyone’s eyes. Those who considered lineage
                                            crucial – though it is not in God’s eyes – found Muhammad ﷺ descending from a nobility that was the purest of the pure. Those who
                                            practiced physiognomy (assessing a person’s character based on physical appearance) felt that Muhammad ﷺ’s face glowed with integrity.
                                            Those who experienced him firsthand, or later read his biography, found in Muhammad ﷺ a lifestyle of extraordinary
                                            sincerity and conviction.</p>

                                        <p><strong>His Honesty</strong> The Prophet ﷺ was not merely a person whose honesty was testified to, rather his clansmen had officially titled him
                                            as-Sādiq al-Amīn (the Truthful, the Trustworthy). Even when they persecuted him and rejected his message, they still trusted him
                                            with their most precious possessions. Aisha (rA) said, “He ﷺ instructed ‘Ali (rA) to stay behind in Mecca, in order to return all
                                            the trusts the Messenger of Allah ﷺ had for people. There was nobody in Mecca (even his enemies!) who had valuables that he feared
                                            for except that he kept them with the Messenger of Allah ﷺ, due to the honesty and trustworthiness
                                            that was known [to all] about him.</p>

                                        <p>Thus, ‘Ali (rA) stayed back for three days and three nights to deliver everything entrusted by the people to the Messenger of
                                            Allah ﷺ, and then caught up with him ﷺ after completing that task.1His principled honesty was so evident that even people from
                                            different eras, backgrounds, and religions recognize it. Indeed, you frequently find them unable to imagine a fair person
                                            reading his life and arriving at a different conclusion.</p>

                                        <p>In fact, although the Scottish philosopher and historian Thomas Carlyle (d. 1881) certainly had his reservations about Islam, his
                                            fascination with the Final Prophet's ﷺ sincerity at times bordered between deep intrigue and apparent conviction. For instance,
                                            he explains, “It goes greatly against the impostor theory, the fact that he lived in this entirely unexceptional, entirely quiet and
                                            commonplace way, till the heat of his years was done. He was forty before he talked of any mission from Heaven. All his irregularities,
                                            real and supposed, date from after his fiftieth year, when the good Kadijah died.</p>

                                        <p>All his ‘ambition,’ seemingly, had been, hitherto, to live an honest life; his ‘fame,’ the mere good opinion of neighbors that knew
                                            him, had been sufficient hitherto. Not till he was already getting old, the prurient heat of his life all burnt out, and peace
                                            growing to be the chief thing this world could give him, did he start on the ‘career of ambition;’ and, belying all his past
                                            character and existence, set up [by others] as a wretched empty charlatan to acquire what he could no longer enjoy! For my share,
                                            I have no faith whatever in that [imposter theory].”2 In the same book, Carlyle says, “The lies (Western slander) which
                                            well-meaning zeal has heaped round this man (Muhammad) are disgraceful to ourselves only.”</p>

                                        <p>In the history of humanity, many imposters have claimed prophethood, and they have all been found void of virtuosity and unethically opportunistic. This is endemic to and expected from, imposter prophets – for they have embraced being the most sinister of liars. Just as lying about your friends is worse than lying about a random person, and just as lying about your parents is worse than lying about your friends, there is nothing uglier than a person lying about God. Therefore, when a man with the undisputed honesty of Muhammad ﷺ lives for forty years without ever lying, then claims to be God’s Prophet, this should be seen as a sign of the veracity of his claim.</p>
                                        <p>Finally, “The sun eclipsed in the lifetime of Allah’s Messenger ﷺ on the day when [his son] Ibrāhīm died. The people said that the sun had eclipsed because of the death of Ibrāhīm. Allah’s Messenger ﷺ said, “The sun and the moon do not eclipse because of the death or life (i.e., birth) of anyone. Rather, they are two of the signs of Allah, by which He instills fear in His slaves. When you see the eclipse, pray and invoke Allah.”3 Had the Prophet ﷺ been an imposter, this would have been the perfect opportunity to capitalize on such a credibility booster.</p>
                                        <p>These coinciding events opened an extremely convenient window for self-promotion, and yet, the Prophet ﷺ would not even let others interpret this as the skies being saddened for Ibrāhīm. Though hurting from the tragic loss, he ﷺ ascended the pulpit, dismissed the false interpretation, and established that eclipses follow nothing but the universal laws of God.</p>
                                        <p><strong>His Austerity</strong> and Asceticism The simple, austere lifestyle of the Prophet ﷺ is a major indication that his mission could not have been self-serving. After all, this was a man who controlled all of Arabia by the end of his life. Even before that, he had thousands of followers throughout his stay in Madinah; followers who obsessed over him, and would have loved to do anything in the world for him. With all that considered, we find no luxury in any sphere of his life. Rather, we find in his apartment a straw mat that would leave marks on his side, and a hanging leather water skin from which he bathed. When he ﷺ wished to prostrate in this room, he would tap Aisha (rA) to bend her legs to make room for him to prostrate.</p>
                                        <p>For months on end, no fire would be kindled for cooking in his home, and his family had to be content with dates and water unless a Companion gifted them some milk.</p>
                                        <p>Edward Gibbon (d. 1794), a historian and member of England’s Parliament, wrote, “The good sense of Muhammad despised the pomp of royalty. The Apostle of God submitted to the menial offices of the family; he kindled the fire; swept the floor; milked the ewes; and mended with his own hands his shoes and garments. Disdaining the penance and merit of a hermit, he observed without effort or vanity the abstemious diet of an Arab.”4 In other words, he ﷺ not just endured the coarseness of an austere life, but it flowed naturally from him. He was not trying to encourage monkhood or self-deprivation, nor was he faking this minimalism to earn praise from the people. Gibbons continues, “On solemn occasions, he feasted his companions with rustic and hospitable plenty. But, in his domestic life, many weeks would pass without a fire being kindled on the hearth of the Prophet.”</p>
                                        <p>According to Washington Irving (d. 1859), an American biographer and diplomat, “He was sober and abstemious in his diet and a rigorous observer of fasts. He indulged in no magnificence of apparel, the ostentation of a petty mind; neither was his simplicity in dress affected but a result of real disregard for distinction from so trivial a source … His military triumphs awakened no pride nor vainglory, as they would have done had they been effected for selfish purposes. In the time of his greatest power, he maintained the same simplicity of manners and appearance as in the days of his adversity. So far from affecting a regal state, he was displeased if, on entering a room, any unusual testimonials of respect were shown to him.”</p>
                                        <p>Bosword Smith (d. 1908), a reverend, schoolmaster, and author writes, “Head of the State as well as the Church; he was Caesar and Pope in one; but he was Pope without the Pope’s pretensions, and Caesar without the legions of Caesar, without a standing army, without a bodyguard, without a police force, without a fixed revenue. If ever a man ruled by a right divine, it was Muhammad, for he had all the powers without their supports. He cared not for the dressings of power. The simplicity of his private life was in keeping with his public life.”</p>
                                        <p><strong>His Bravery</strong>  The great bravery of the Prophet ﷺ meant that he was not only truthful, but certain of his truthfulness. Some orientalists, though all are derisive in depicting Islam, choose to argue that Muhammad ﷺ was not consciously lying, but suffered from a mental illness that subjected him to delusions of grandeur. This claim crumbles at the feet of the Prophet’s documented bravery, because someone who “hears voices” and has psychological disorders would be inconsistent, reluctant, and incapable of achieving all of the triumphs he achieved. More importantly, a mentally disturbed person would never come up with a complete system of beliefs and laws that would earn the respect of thousands of historians, philosophers, and other men of wisdom.</p>
                                        <p>Therefore, the consistent and matchless bravery of Muhammad ﷺ becomes a guarantor in support of his prophethood. The Prophet ﷺ never fled in battle; rather, he fought fearlessly on the front lines. It would have been perfectly understandable for the Prophet ﷺ to shield himself behind the army, for his death would mean the end of the message. Yet, ‘Ali b. Abi Ṭālib (rA), the celebrated warrior, would say, “I myself witnessed on the Day of Badr how we used to stay close to the Prophet ﷺ for cover, and he was the closest of us to the enemy, and he was the fiercest [warrior] on that day.”</p>
                                        <p>A man once said to al-Barā’ b. ‘Âzib (rA), “Did you flee on the Day of Ḥunayn, O Abu ‘Umāra?” He replied, “I can testify that the Prophet of Allah ﷺ did not retreat. Rather, it was some hasty young men who met – without adequate arms – a group from Hawāzin and Banu Naḍīr. They happened to be [excellent] archers, and they shot at them a volley of arrows that exposed [their ranks]. The people turned [for help] to the Messenger of Allah ﷺ, whose mule was being led by Abu Sufyān b. Harb. He ﷺ dismounted, prayed, and invoked God’s help. What he said was, “I am the Prophet; this is no untruth. I am the son of ‘Abdul-Muṭṭalib! O Allah, send your help!” By Allah, when the battle grew fierce, we would seek protection behind him, and [only] the bravest among us could stand by his side [in battle].”</p>
                                        <p>When Allah revealed, “O Messenger, convey that which has been revealed to you… and Allah will protect you from the people” [al-Mā’ida (5): 67], the Prophet ﷺ forbade his Companions from continuing to stand guard at night by his door.9 One night, the Muslims – who remained anxious that the Romans would attack Madinah at any time – awoke startled by a loud crash. Rushing to the scene, they found the Prophet ﷺ already returning on a bareback horse belonging to Abu Ṭalḥa, his sword hanging around his neck, reassuring them that it was a false alarm.10 It takes exemplary courage, racing out alone to face potential danger like that, courage that even the bravest souls would admire.</p>
                                        <p><strong>His Perseverance</strong> The Prophet Muhammad ﷺ did not only display bravery at specific junctions in his life, but rather showed exemplary endurance and consistency throughout. Consider a man who never knew his father firsthand, hardly enjoyed the compassion of his mother, and then lost his grandfather, and then his uncle and dearest wife simultaneously. Consider a man who lived to witness every single one of his children die save for one, who was treated like a menace and fugitive after decades of building a flawless reputation among his people. Consider a man who experienced physical abuse until he would faint, was starved for years by his own people, and faced countless campaigns of character assassination. Consider a man who was driven out of his home, sent fleeing to Madinah for shelter, only to find hypocrites there awaiting every opportunity to betray him.</p>
                                        <p>Consider a man watching assassination attempts against his life unfold regularly, as well as the murder and mutilation of his relatives and companions, and then the slander of his cherished wife Aisha (rA), the daughter of his most loyal comrade. Who could persevere with hope, and persist in matchless ethics, through all this except someone infused by a unique aid from the heavens? The Prophet ﷺ rose from that abyss of negativity and not only survived, but became a fountain of mercy and empathy for people, animals, and plants alike. This is nothing short of miraculous; only God brings the dead out of the living, and produces a spring from a rock, and nourishes a rose in the desert. Only God could have kept him smiling throughout, playing with his grandchildren, standing by his principles, and lifting the spirits of those who suffered so much less than him. Only God could have empowered him ﷺ to have compassion for the heartless, forgiveness for his enemies, and concern for the arrogant</p>
                                        <p>Though the incidents reflecting his perseverance are innumerable, any honest person can simply familiarize himself with the embargo in the ravine of Abu Ṭālib. This continued for an utterly brutal three years, wherein the Prophet ﷺ watched the lips of his Companions turn green from eating leaves and gnawing at animal hides out of desperation for any nourishment. In fact, he watched his dearest family members slowly deteriorate in front of his eyes. Khadīja (rA) and Abu Ṭālib were so debilitated by the embargo that they never recovered from it; they both died soon thereafter. And with the protection of Abu Ṭālib gone, the Prophet ﷺ received in that year the most humiliating treatment of his life.</p>
                                        <p>Aisha (rA) reported that she once asked the Prophet ﷺ, “Have you encountered a day harder than the Day of Uḥud?” The Prophet ﷺ said, “Your tribe has troubled me very much, and the worst was the day of ‘Aqaba when I presented myself to ‘Abd Yalāyl b. ‘Abd Kulāl, and he did not respond to what I sought. I [eventually] departed, overwhelmed with grief, and I could not relax until I found myself at a tree where I lifted my head towards the sky to see a cloud shading me. I looked up and saw Gabriel in it. He called out to me, saying, ‘Allah has heard your people’s speech to you and how they have replied, and Allah has sent the Angel of the Mountains to you that you may order him to do whatever you wish to these people.’</p>
                                        <p>The Angel of the Mountains greeted me and said, ‘O Muhammad, order what you wish, and if you like, I will let the two mountains fall upon them.’ I said, ‘No, rather I hope that Allah will bring from their descendants people who will worship Allah alone without associating partners with Him.”11 In other reports, he ﷺ spent ten days in Ṭā’if after speaking to its leaders, calling its people to Islam, until mobs gathered to drive him out. They made two rows and forced him through them while they hurled obscenities and pelted stones until blood ran down his blessed legs, and Zayd b. Ḥaritha’s head was gashed.12 But even in that darkest hour, all this compounded anguish still did not break the Prophet ﷺ’s perseverance.</p>
                                        <p><strong>His Optimism</strong> It is remarkable how the Prophet Muhammad ﷺ never lost hope in the support and victory of Allah, regardless of how apparently hopeless his situation would sometimes become.13 This optimism tells of a heart laced with supreme faith. Upon leaving Mecca for the migration, the Prophet ﷺ and Abu Bakr (rA) were tracked to a cave. Mercenaries stood at the mouth of the cave, and simply had to bend over to look inside, and nothing would have prevented them from noticing and capturing the Prophet ﷺ. In that unnerving moment, when despair would penetrate even the firmest of souls, the Prophet ﷺ calmly utters to his Companion an unbelievable statement: “O Abu Bakr, what do you think of two – when Allah is their third?”14 The Quran later referenced this incident by saying, “If you do not aid the Prophet – Allah has already aided him when those who disbelieved had driven him out [of Mecca] as one of two, when they were in the cave and he said to his companion,</p>
                                        <p>‘Do not grieve; indeed Allah is with us.’ And Allah sent down His tranquility upon him and supported him with angels you did not see and made the word of those who disbelieved the lowest, while the word of Allah – that is the highest. And Allah is Exalted in Might and Wise” [at-Tawba (9): 40]. A person may say: how do we know that Muhammad did not fabricate this verse after the event, portraying with it a dishonest image about his unwavering conviction in God? The simplest response is that Abu Bakr (rA) personally witnessed how calm and collected the Messenger of Allah ﷺ was in those terrifying minutes. Had he ﷺ not been so, would Abu Bakr (rA) have remained his greatest admirer and continued venerating him as the embodiment of integrity, even after the Prophet ﷺ’s death?Thus was the conviction the Prophet Muhammad ﷺ had in his faith, whereby the promise of Allah which his heart saw would override the hopelessness his eyes saw.</p>
                                        <p>This is identical to what occurred to Moses (as) at the shore; “And when the two companies (the Israelites and Pharaoh’s legions) saw one another, the companions of Moses said, ‘Indeed, we are to be overtaken!’ He (Moses) said, ‘No! Indeed, with me is my Lord; He will guide me.” [ash-Shu‘arā’ (26): 61-62] This degree of certitude was unique to the Prophets and Messengers; even if the world lost hope, they would never unravel.</p>
                                        <p><strong>His Followers</strong> When analyzing the veracity of Muhammad’s prophethood, one would be remiss to limit his followers to the noble Companions. Prophet Muhammad ﷺ is the single most influential personality in human history (see: The 100, by Michael Hart), being imitated in the most private, or nuanced, particularities of his life. For a millennium and a half, there has been a worldwide fascination with resembling the final Prophet, even at the cost of opposing the dominant culture or current trends. For 1.6 billion people today, that is a small price to pay for mirroring the most ideal human being that ever lived.</p>
                                        <p>David George Hogarth (d. 1927), a British scholar and archeologist, said, “Serious or trivial, his daily behavior has instituted a canon which millions observe this day with conscious memory. No one regarded by any section of the human race as Perfect Man has ever been imitated so minutely. The conduct of the founder of Christianity has not governed the ordinary life of his followers. Moreover, no founder of a religion has left on so solitary an eminence as the Muslim apostle.”</p>
                                        <p>However, his most profound followers were undoubtedly his Companions, and this is what distinguishes their testimony to the truth of his message. These Companions were not a mere bunch of good fellows who prayed at night, devoted their lives to God, but shared a collective naivety. This was, without bias, the most significant generation in the history of mankind. The genius of ‘Umar b. al-Khaṭṭāb (rA), for instance, has been recognized by many historians. In fact, the Columbia History of the World asserts how ‘Umar effected a superior bureaucracy than that of the juggernaut Roman Empire which preceded Muslim rule.</p>
                                        <p>When a tribal Arab can construct a federal government with centralized power, but also the flexibility to make the peripheries of this union sustainable —and by that change the course of history— one should concede that this was an exceptional mind. This is but one person who believed in the prophethood of Muhammad ﷺ, one who mimicked him with an obsessive admiration. Abu Bakr giving half his wealth for the sake of Islam, Bilāl refusing to recant his beliefs despite unthinkable torture, Sumayya and Yāsir being tortured to death for refusing to even pretend they did not believe in Muhammad ﷺ, are not petty events. As for those who survived, they undertook the momentous task of inculcating his spirit and message into their lives, making them most deserving of God’s praise, “You are the best nation produced [as an example] for mankind.</p>
                                        <p>You enjoin what is right and forbid what is wrong and believe in Allah.” [Âl ‘Imrān (3): 110] Being followed so ardently by this caliber of people, who experienced him before and after prophethood, and witnessed his behavior on a daily basis, is a clear gauge as to how certain they were in him ﷺ and his mission.</p>
                                        <p>William Montgomery Watt (d. 2006), a Scottish historian and Emeritus Professor in Arabic and Islamic Studies, wrote, “His readiness to undergo persecution for his beliefs, the high moral character of the men who believed in him and looked up to him as a leader, and the greatness of his ultimate achievement – all argue his fundamental integrity. To suppose Muhammad an imposter raises more problems than it solves. Moreover, none of the great figures of history is so poorly appreciated in the West as Muhammad… Thus, not merely must we credit Muhammad with essential honesty and integrity of purpose, if we are to understand him at all; if we are to correct the errors we have inherited from the past, we must not forget that conclusive proof is a much stricter requirement than a show of plausibility, and in a matter such as this only to be attained with difficulty.”</p>


                                    </div>
                                </Grid>

                            </Grid>

                        </>
                    }
                />



            </div>

            <Footer />
        </div>

    );
}


export default TheCharacterofProphet;