import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { CardEleven, Section, CardSixteen, ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import EventImgOne from "../assets/imgs/ImgThree.png";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import PageTitle from "./PageTitle";

import ReadImgOne from "../assets/imgs/reads/read-img-one.png";
import ReadImgTwo from "../assets/imgs/reads/read-img-two.png";
import ReadImgThree from "../assets/imgs/reads/read-img-three.png";

import { getNews } from "../backend.js";

function ReadsSection() {
  const [isOpen, setOpen] = useState(false);

  const [firstFeaturedRead, setFirstFeaturedRead] = useState(null);
  const [featuredReads, setFeaturedReads] = useState([]);
  const [moreReads, setMoreReads] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getNews();

        let data = response?.data?.body?.data ?? [];

        let featuredReads = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" &&
            featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        let moreReads = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" &&
            !featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        featuredReads = featuredReads.map((item) => {
          return {
            id: item._id,
            image: item.article.imageMetaData.imageThumbnail,
            title: item.article.title,
            link: `/reads-details/${item._id}`,
          };
        });

        moreReads = moreReads.map((item) => {
          return {
            id: item._id,
            image: item.article.imageMetaData.imageThumbnail,
            title: item.article.title,
            link: `/reads-details/${item._id}`,
          };
        });

        if (featuredReads.length === 0 && 
          moreReads.length >= 3)
          {
            featuredReads.push(moreReads.shfit());
            featuredReads.push(moreReads.shfit());
            featuredReads.push(moreReads.shfit());
          }

        setFirstFeaturedRead(featuredReads.shift());
        setFeaturedReads(featuredReads);
        setMoreReads(moreReads);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const eventsData = [
    {
      img: EventImgOne,
      title:
        "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
      //link: "/reads-details",
    },

    {
      img: EventImgOne,
      title:
        "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
      //link: "",
    },

    // {
    //     img: EventImgOne,
    //     title: "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
    //     link: "/reads-details",
    // },

    // {
    //     img: EventImgOne,
    //     title: "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
    //     link: "/reads-details",
    // },

    // {
    //     img: EventImgOne,
    //     title: "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
    //     link: "/reads-details",
    // },

    // {
    //     img: EventImgOne,
    //     title: "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
    //     link: "/reads-details",
    // },
  ];

  return (
    <div className="FeaturedEvents">
      <PageTitle Title="Reads and views" Path="Reads" />

      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <>
            <Grid container spacing={3} style={{ marginBottom: "80px" }}>
              {firstFeaturedRead && (
                <Grid item md={8} xs={12}>
                  <CardEleven
                    img={firstFeaturedRead.image}
                    ImgHeight="410px"
                    title={firstFeaturedRead.title}
                    FontSize="40px"
                    TitleSpace="89px"
                    link={firstFeaturedRead.link}
                    titleColor="#0B132B"
                    buttonLabel="READ MORE"
                    ReadmoreIcon={<ArrowForwardRoundedIcon />}
                    ReadmoreColor="#676C7B"
                    border="border"
                  />
                </Grid>
              )}

              {featuredReads.length > 0 && (
                <Grid item md={4} xs={12}>
                  <Grid container spacing={3}>
                    {featuredReads.map((item, index) => (
                      <Grid key={item.id} item md={12} xs={12}>
                        <CardEleven
                          img={item.image}
                          ImgHeight="196px"
                          title={item.title}
                          link={item.link}
                          titleColor="#0A2540"
                          buttonLabel="READ MORE"
                          ReadmoreIcon={<ArrowForwardRoundedIcon />}
                          ReadmoreColor="#676C7B"
                          border="border"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <div className="AboutContents">
                  <h2>More Reads And Views</h2>
                </div>
              </Grid>

              {moreReads.map((item, index) => (
                <Grid key={item.id} item md={6} xs={12}>
                  <div className="ListingCard eventlistcard">
                    <CardSixteen
                      image={item.image}
                      title={item.title}
                      buttonText="READ MORE"
                      link={item.link}
                      readmoreColor="#fff"
                    />
                  </div>
                </Grid>
              ))}

              {/* <Grid item md={12} xs={12}>
                                <ButtonOne
                                    label="LOAD MORE"
                                    link=""
                                    target="_blank"

                                    arrowIcon={<ExpandMoreRoundedIcon />}
                                    arrowStatus={true}
                                    arrowColor="#DB9E30"

                                    buttoncolor="#F5F8FC"
                                    width="100%"
                                    height="50px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    color="#DB9E30"
                                    fontWeight="600"
                                    textAlign="center"
                                />
                            </Grid> */}
            </Grid>
          </>
        }
      />
    </div>
  );
}

export default ReadsSection;
