import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import ReadsDetailsSection from '../ReadsDetailsSection'

import '../../assets/css/style.css'
import '../../assets/css/responsive.css'

import { useParams } from "react-router-dom";

function ReadsDetails(props) {

  const { id } = useParams();

  return (

    <div>
      <Header />
      <NavBar />
      <ReadsDetailsSection id={id} />
      <Footer />
    </div>

  );
}


export default ReadsDetails;