import React from 'react';
import { NavBarTen } from "master-react-lib";

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

function NavBar() {

    const menuData = [
        {
            label: "HOME",
            link: "/",
            hasChildren: false,
        },
        {
            label: "ABOUT US",
            hasChildren: true,
            children: [
                { label: "ABOUT US", link: "/about-us" },
                { label: "OUR MISSION & VISION", link: "/mission" },
                { label: "BOARD OF DIRECTORS", link: "/board-of-directors" },
                { label: "OUR SERVICES", link: "/our-services" },
                { label: "CONTACT US", link: "/contact-us" },
            ]
        },

        {
            label: "EVENTS",
            link: "/events",
            hasChildren: false,

        },
        {
            label: "READS",
            link: "/reads",
            hasChildren: false,

        },

        {
            label: "VIDEOS",
            link: "/videos",
            hasChildren: false,

        },

        {
            label: "ACADEMIC PROGRAMS",
            link: "/academic-programs",
            hasChildren: false,

        },

        {
            label: "DAWAH",
            link: "/dawah",
            hasChildren: false,

        },

        {
            label: "FAQ",
            link: "/faq",
            hasChildren: false,

        },


    ];


    return (
        <div className='NavBarContainer'>
            <NavBarTen
                menuData={menuData}
                MenuStatus={true}
                menuiconColor="#fff" 
                background="#B9278A"
                maxWidth="1175px"
                position="relative"
                color="#fff"
                hoverColor="#000"


                //// Button Style ////
                buttonLebel="DONATE NOW"
                ButtonLink="https://give.deenfund.com/theabrar"
                target="blank"
                path="/"
                arrowIcon={<ArrowForwardIosRoundedIcon />}
                arrowStatus={false}
                arrowColor="#B9278A"
                buttoncolor="#fff"
                width="auto"
                height="47px"
                border="#ccc 0px solid"
                borderRadius="4px"
                colorlabel="#B9278A"
                fontWeight="600"
                textAlign="center"
                handIconStatus={false}

            />
        </div>
    );
}


export default NavBar;