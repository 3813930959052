import { React, useState, useEffect } from "react";
import { SalatTable } from "master-react-lib";
import { getSalahs, getOrganization } from "../backend.js";
import moment from "moment";

function Salah() {
  // Commented by Hussain.
  const [salahs, setSalahs] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(
    new Date("2001-01-01T00:00:00.000Z")
  );

  useEffect(() => {
    const getTimeStamp = (time) => {
      let timestamp = moment(time).format("x");
      return timestamp;
    };

    const getData = async () => {
      try {
        let response = await getSalahs();

        let data = response?.data?.body?.data ?? [];

        data = data.filter((salah) => salah.status === "true");

        response = await getOrganization();

        const enabledIntegrations =
          response.data?.body?.data?.enabledIntegrations;

        let selectedSalahs =
          enabledIntegrations?.deenweb?.salahs?.selectedSalahs;
        selectedSalahs = selectedSalahs.filter(
          (salah) => salah.status === "Public"
        );

        const selectedSalahIDs = selectedSalahs.length
          ? selectedSalahs.map((item) => item.salah)
          : [];

        data = data.filter((salah) => selectedSalahIDs.includes(salah._id));

        data = data
          ?.filter((salah) => salah.order)
          .sort((salah1, salah2) => {
            return salah1.order - salah2.order;
          });

        data.sort((a, b) => a.order - b.order);

        data = data?.length
          ? data.sort((a, b) => {
              return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt);
            })
          : [];

        let salahs = data.map((item) => {
          let name = item?.salahName?.fullName?.split("(");

          name = name?.length ? name[0] : item?.salahName.fullName;

          let salahTime = item?.salahStart?.iqamahTime
            ? moment(item?.salahStart?.iqamahTime).utcOffset(0).format("LT")
            : "";

          const updatedAt = new Date(item.updatedAt);

          if (updatedAt > lastUpdated) setLastUpdated(updatedAt);

          return {
            col1: name,
            // col2: new Date(item.salahStart.iqamahTime).toLocaleString(
            // "en-US",
            // { hour: "numeric", minute: 'numeric', hour12: true }
            // ),
            col2: salahTime,
          };
        });

        setSalahs(salahs);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [lastUpdated]);

  const tableBodyData = [
    { col1: "Fajr" /*, col2: "5:00 AM"*/, col2: "5:00 AM" },
    { col1: "Zuhr" /*, col2: "1:30 PM"*/, col2: "1:30 PM" },
    { col1: "Asr" /*, col2: "1:30 PM"*/, col2: "1:30 PM" },
    { col1: "Maghrib" /*, col2: "6:50 PM"*/, col2: "4:45 PM" },
    { col1: "Isha" /*, col2: "4:10 AM"*/, col2: "6:50 PM" },
    { col1: "Jumu'ah" /*, col2: "8:15 PM"*/, col2: "8:15 PM" },
    { col1: "Jumu'ah 2" /*, col2: "8:15 PM"*/, col2: "8:15 PM" },
  ];

  return (
    <div className="SlahTableCtn">
      <SalatTable
        TableTitle="Salah Times"
        tabletitleStatus={true}
        tableBodyData={salahs}
        //odd="#DB9E30"
        //even="#DB9E30"
        colBorder="#E4E8EC 0px solid"
        col1Color="#fff"
        col2Color="#fff"
        ColPadding="15px 30px !important"
        // footerText={`Last updated: ${lastUpdated.toLocaleString("en-US", {
        //   dateStyle: "full",
        //   timeStyle: "short",
        // })}`}
        //footerText="Last Updated: January 10, 2022"
        footerText={
          lastUpdated && (
            <p>
              Last Updated {lastUpdated ? moment(lastUpdated).format("LL") : ""}{" "}
              /{" "}
              {lastUpdated
                ? moment(lastUpdated).utcOffset(-5).format("h:mm A") + " EST"
                : ""}
            </p>
          )
        }
        footerTextStatus={true}
        bottomTextSpace="21px"
        TableHead={true}
        TableHeadColor="#000"
        col3Status={false}


        col_1Title="Salah"
        //col_2Title="Adhan"
        col_3Title="Iqamah"
      />
    </div>
  );
}

export default Salah;
