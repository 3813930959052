import React from 'react';
import { CTAOne, ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

function CtaOneCardDark() {




    return (
        <div className='CtaOneCardDark'>
            <CTAOne
                backgroundColor="#0B132B"
                Title="Build a house of Allah, and Allah will build one for you in Jannah"
                SubTitle="Al-Tirmidhi"
                ButtonLebel="DONATE NOW"
                link="https://give.deenfund.com/theabrar"
                target="_blank"
                minHeight=""

                Btnbackground="#DB9E30"
                Btncolor="#fff"
                BtnPadding="18px 16px"
                arrowIcon={<ArrowForwardRoundedIcon />}
                buttonWidth=""

            />

            <div className='CtaButtons'>
                
                <ButtonOne
                    label="DONATE FOR ZAKAT"
                    link="https://give.deenfund.com/theabrar"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#fff"

                    buttoncolor="#DB9E30"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#fff"
                    fontWeight="600"
                />

                <ButtonOne
                    label="DONATE FOR SADAQAH"
                    link="https://give.deenfund.com/theabrar"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#fff"

                    buttoncolor="#DB9E30"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#fff"
                    fontWeight="600"
                />

            </div>

        </div>
    );
}


export default CtaOneCardDark;