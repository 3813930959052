import React from 'react';
import { Section, ButtonOne } from "master-react-lib";
import backgroundPattern from "../assets/imgs/PageHeaderBG.svg";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import resourceIcon from '../assets/imgs/resourcesIcon.png';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import PageTitle from "./PageTitle";


function Resource() {

  

    return (
        <div className='ResourceSection'>


            <PageTitle
                Title="Dawah"
                Path="Dawah"
            />

            <div className='FeaturedEvents'>
                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="80px"
                    Container={
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>
                                <h1 className='InnerPageTitleGrey'>Your Ultimate Dawah Resource!</h1>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <ButtonOne
                                    label="SEE MORE"
                                    link=""
                                    target="_blank"

                                    arrowIcon={<ExpandMoreRoundedIcon />}
                                    arrowStatus={true}
                                    arrowColor="#DB9E30"

                                    buttoncolor="#F5F8FC"
                                    width="100%"
                                    height="50px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    color="#DB9E30"
                                    fontWeight="600"
                                    textAlign="center"
                                />
                            </Grid>


                        </Grid>



                    }
                />


            </div>

        </div>
    );
}


export default Resource;