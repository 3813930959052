import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Redirect } from 'react-router';

import HomePage from './components/Pages/HomePage'
import AboutUsPage from './components/Pages/AboutUsPage'
import EventsPage from './components/Pages/EventsPage'
import EventDetails from './components/Pages/EventDetails'
import ReadsPage from './components/Pages/ReadsPage'
import ReadsDetails from './components/Pages/ReadsDetails'
import VideosPage from './components/Pages/VideosPage'
import Dawah from './components/Pages/Dawah'
import FaqPage from './components/Pages/FaqPage'
import WeekendSchool from './components/Pages/WeekendSchool'
import TermsofService from './components/Pages/TermsofService'
import PrivacyPolicy from './components/Pages/PrivacyPolicy'


import TheCharacterofProphet from './components/ReadsDetails/TheCharacterofProphet'
import TheDivineGiftofGratitude from './components/ReadsDetails/TheDivineGiftofGratitude'
import AnIntroduction from './components/ReadsDetails/AnIntroduction'

import Donate from './components/Donate'

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events-details/:id" element={<EventDetails />} />
        <Route path="/reads" element={<ReadsPage />} />
        <Route path="/reads-details/:id" element={<ReadsDetails />} />
        <Route path="/videos" element={<VideosPage />} />
        <Route path="/academic-programs" element={<WeekendSchool />} />
        <Route path="/dawah" element={<Dawah />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/terms-of-service" element={<TermsofService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />


        <Route path="/mission" element={<AboutUsPage />} />
        <Route path="/board-of-directors" element={<AboutUsPage />} />
        <Route path="/our-services" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<AboutUsPage />} />


        <Route path="/the-character-of-prophet" element={<TheCharacterofProphet />} />
        <Route path="/the-divine-gift-of-gratitude" element={<TheDivineGiftofGratitude />} />
        <Route path="/an-introduction-to-ulum-al-quran" element={<AnIntroduction />} />
        
        <Route exact path="/donate" element={<Donate />} />
            

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
export default App;
