import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import Banner from '../Banner'
import NavBar from '../NavBar'
import EventsListing from '../EventsListing'
import SupportUs from '../SupportUs'

import FeaturedServices from '../FeaturedServices'


import '../../assets/css/style.css'
import '../../assets/css/responsive.css'




function HomePage(props) {



  return (

    <div>
      <Header />
      <NavBar />
      <EventsListing />
      <SupportUs />
      <FeaturedServices />
      <Footer />
    </div>

  );
}


export default HomePage;