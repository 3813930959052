import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import Grid from '@material-ui/core/Grid';
import { SocialMediaOne, Section, ButtonOne } from "master-react-lib";
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'

import SocialSection from './SocialSection';


import Banner from '../../assets/imgs/reads/read-img-three.jpeg';



function AnIntroduction(props) {

    return (

        <div>
            <Header />
            <NavBar />

            <div className='FeaturedEvents'>
                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="20px"
                    Container={
                        <>
                            <Grid container spacing={3}>

                                <Grid item md={12} xs={12}>
                                    <SocialSection />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div className="eventDetailsBanner">
                                        <img src={Banner} />
                                    </div>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3}>

                                <Grid item md={5} xs={12}>
                                    <div className="eventDetailsContetns">
                                        <h1>An Introduction to ‘Ulum-al-Qur’an: The Field of Qur’anic Studies</h1>
                                    </div>
                                </Grid>

                                <Grid item md={7} xs={12}>
                                    <div className="eventDetailsContetns">
                                       
                                       <p>“It does not become worn out by repetition and its wonders do not come to an end.”1 One of the marvels of the Qur’an, beyond its transcendent nature, is its vast scope which addresses numerous dimensions of human nature. Every individual approaches the Qur’an from a unique angle driven by an intellectual query or triggered by a spiritual experience. These queries and experiences shape our engagement with the words of the Creator. A primary cause of the Qur’an’s revelation is to foster a personal reflective connection with the Divine through tadabbur.2 Our pursuit of proper tadabbur prompts a multi-faceted examination of the Qur’an’s inimitable nature, textual history, linguistic styles, theological premises, ethical standards, and legal injunctions. Tadabbur subsumes well-established theological stances regarding the Qur’an’s message and spiritual aptitude for its spiritual guidance.</p>
                                       <p>Recipients of the same message vary in their receptive and responsive modes based on their belief and spiritual statuses.3</p>
                                       <p>Unlocking the Qur’anic text was the main objective behind the development of diverse Islamic studies. Hence, tadabbur and other forms of Qur’anic engagement utilize different research methods belonging to a wide array of disciplines. The ongoing evolution of these disciplines necessitated that topics related to the Qur’an’s text and context be compiled under the designated field of Qur’anic Studies (ʿUlūm al-Qurʾān). In this way, Qur’anic Studies emerged as a synthesis of various disciplines rather than an independent area of study. The disciplinary diversity of the field naturally generated many sub-disciplines and sub-genres engaged with the vastness of the Book.</p>
                                       <p>Ulūm al-Qurʾān is therefore an umbrella term for various disciplines and areas of studies related to the Qur’an’s meaning, oral and written history, and rendition. One of the most famous disciplines among Muslims, Qur’anic exegesis (tafsīr), is generally considered part, and one of the goals, of ʿUlūm al-Qurʾān. Tafsīr is by far the most exhaustive intellectual enterprise given its critical object of uncovering the intended meanings behind Allah’s words. It relies on exegetical principles; lexical, grammatical, and rhetorical explanations; Prophetic traditions; circumstances and settings of revelation; variant readings; and abrogation. In addition, the field of ʿUlūm al-Qurʾān is a prerequisite for undertaking a wider textual and contextual study of the meanings of the Qur’an while alerting us to the integrity and inimitability of the text.</p>
                                       <p>This article introduces the history and development of ʿUlūm al-Qurʾān, provides a mental map of its major disciplines, highlights its key classical contributions, and gives examples of how this field furnishes a deeper connection to the multi-layered nature of the Qur’anic text. First, we define the terms Qur’an and ʿUlūm al-Qurʾān. Second, we introduce some classical masterpieces in the field. Third, we introduce and elaborate on Imam al-Bulqīnī’s taxonomy of 50 Qur’anic sub-disciplines grouped in six categories: 1) circumstances of revelation, 2) transmission, 3) phonetics and elocution, 4) rhetorical devices, 5) interpretive principles, and 6) rhetorical styles.</p>
                                       <p>These technical disciplinary designations not only facilitate Qur’anic research and exegetical endeavours, but also strengthen our exercise of tadabbur by showcasing multiple approaches to engaging with the text and providing numerous tools of comprehension and reflection. This article’s survey of ʿUlūm al-Qurʾān serves two purposes. First, it provides a roadmap for Muslims seeking an accessible overview of approaches to the Qur’an. Second, it offers a pedagogical resource for students of knowledge, introducing a brief review of the literature and outlining the disciplinary designations of the field.</p>
                                       <p><strong>Definition</strong> of the Qur’an Although the term Qurʾān is the most frequently used in the Book to refer to itself, Allah describes it with several alternate names such as al-Furqān (the Criterion), al-Dhikr (the Reminder), and al-Kitāb (the Book). Many characteristics of the Qur’an are also mentioned within such as “glad tiding” (bushrā)4; “knowledge” (ʿilm)5; “trustworthy handhold” (al-ʿurwah al-wuthqā);6 “the Truth” (ḥaqq);7 ‘the rope of Allah” (ḥabl Allāh);8 and “a clear statement for mankind” (bayān li-l-nās).9 Reflecting on these Qur’anic names increases our faith in the revelation, deepens our understanding of its message, and strengthens our connection with our Creator.10 Prior to exploring the different approaches of ʿUlūm al-Qurʾān, a brief discussion of the scholarly definition of the Qur’an itself will help guide our understanding of its genres and their disciplinary designations.</p>
                                       <p>Multi-disciplinary engagements with the Qur’an resulted in multi-dimensional definitions of the sacred text, with each definition highlighting a unique approach to the Book of Allah.11 Some linguists held that the word Qurʾān is a proper noun.12 Others held that the word is a verbal noun derived from the root-verb13 qaraʾa which means ‘read’ or ‘compile’ (jamaʿa).14 Although Qur’anic verses frequently use the word qurʾān to denote ‘recitation,’15 different derivations of qaraʾa also signify meanings of understanding, reflection, learning, worship, and following. Jamaʿa implies the compilation of the Book’s chapters and verses.</p>
                                       <p>The technical definition of the Qur’an aims to describe the words of Allah preserved by oral transmission and written codices. Being concerned with the canonicity and the authenticity of the text, most disciplines (including Qur’anic studies, law, theology and linguistics) demarcate the boundaries of Allah’s final message by focusing on a common set of textual characteristics including inimitability (iʿjāz), mass transmission (tawātur), and conformity with the ʿUthmānic orthography (rasm). Accordingly, a common interdisciplinary definition of the Qur’an is: The speech of Allah revealed upon His Prophet Muhammad ﷺ. Its recitation is used in acts of worship and its smallest chapter is miraculous in nature.16</p>
                                       <p><strong>The scope and significance of ʿUlūm al-Qurʾān</strong> The field of ʿUlūm al-Qurʾān refers to groups of principles, rules, and characteristics that coherently relate to some aspect of the Qur’an.17 However, the field was not traditionally given a particular technical definition by even its most famous works such as al-Burhān fī ʿUlūm al-Qurʾān by al-Zarkashī (d. 794/1392) and al-Itqān fī ʿUlūm al-Qurʾān by al-Suyūṭī (d. 911/1505). The word ʿUlūm (s. ‘ilm) denotes the multiplicity of disciplines concerning the Qur’an. Some scholars treat the term ulūm generally to mean what is known (maʿlūm), which encompasses any stated or implied information. Hence, any datum related to the Qur’an from any perspective can be included in the realm of Qur’anic sciences.</p>
                                       <p>For example, al-Ghazālī (d. 505/1111) reported the opinion of some scholars that the Qur’an contains 77,000 sciences which are then multiplied by four since every word in the Qur’an has an inward meaning, an outward meaning, an aspect (ḥadd), and a perspective (maṭlaʿ or muṭṭalaʿ).18[a][b][c][d][e] Similarly, al-Qādī Ibn al-ʿArabī (d. 544/1148) cited various scholarly opinions placing the number of the Qur’anic sciences in the range of 50 to 70,000.19</p>
                                       <p>There is more to explore in the Qur’an that any human effort can encompass. It is the manifestation of endless marvels which believers are encouraged to ponder, identify, and reflect on in everyday life. Notwithstanding the classical tradition’s tremendous scholarship on the Qur’an, this framework invites us to engage creatively with Allah’s revelation. In this regard, al-Ghazālī’s suggestion can be taken to mean that human capabilities of knowledge have the potential to develop new areas of studies and research that never existed before.20</p>
                                       <p><strong>Brief history of the classical field of ʿUlūm al-Qurʾān</strong> We do not know of any scholarly works before the 4th/10th century that addressed ʿUlūm al-Qurʾān as an independent set of disciplines in the manner common today.21 Rather, ʿUlūm al-Qurʾān topics were addressed across tafsīr introductions, legal tafsīrs (aḥkām al-Qurʾān), topics of legal proofs (mabāḥith al-adillah) in books of legal theory, and specialized works on particular Qur’anic topics.</p>
                                       <p>Although some early books included the term ʿUlūm al-Qurʾān in their titles, such as al-Ḥāwī fī ʿUlūm al-Qurʾān by Muḥammad ibn Khalaf al-Marzubān (d. 309/921) and al-Burhān fī ʿUlūm al-Qurʾān22 by ʿAlī ibn Ibrāhīm al-Ḥufī (d. 430/1038), the voluminous sizes of their manuscripts indicate that they were tafsīrs rather than books exclusively dedicated to ʿUlūm al-Qurʾān. An example of an extant book is Makkī ibn Abī Tālib’s (d. 437/951) tafsīr “al-Hidāyah ilā Bulūgh al-Nihāya,” in which he incorporated many of the earlier works on tafsīr and ʿUlūm al-Qurʾān. In the introduction, Makkī said that he relied on al-Istighnā by Abū Bakr al-Udfuwī (d. 388/998), which dedicated about 300 sections to ʿUlūm al-Qurʾān.23 Makkī added that he drew from more than 1000 works on ʿUlūm al-Qurʾān, in addition to tafsīrs.24</p>
                                       <p>Fahm al-Qurʾān by al-Muḥāsabī (d. 243/587) is arguably the earliest work on ʿUlūm al-Qurʾān,25 prior to the technical designation of the term, that is available today. Beginning from the 5th/11th century, tremendous scholarship was undertaken on ʿUlūm al-Qurʾān such as al-Burhān fī Mushkilāt al-Qurʾān by ʿAbdulmalik ibn Mansūr al-Jīlī, known as Shaidhalah, (d. 494/1100), Funūn al-Afnān by Ibn al-Jawzī (d. 597/1200), Jamāl al-Qurrā by al-Sakhāwī (d. 643/1245), and al-Murshid al-Wajīz by Abū Shāmah (d. 665/1266). Except for the first book, all of these works remain available in print today.</p>
                                       <p>Since designation and classification of disciplines were matters of individual reasoning (ijtihād), scholars continued to disagree over the number of Qur’anic sciences. Al-Zarkashī cited several scholars in al-Burhān who classified the Qur’an as having three main themes.26 For example, al-Ṭabarī (d. 310/923) said that Qur’anic themes include monotheism (tawḥīd), reports (akhbār), and rituals (diyānāt). ʿAlī ibn ʿEisā (d. 384/994) extended the list to include 30 themes (though according to al-Zarkashī, Shaidhalah considered all of ʿEisa’s themes to be encompassed in al-Ṭabarī’s three). Ibn al-ʿArabī divided the Qur’an’s topics into monotheism (tawḥīd), reminders (tadhkīr), and rulings (aḥkām). Abū al-Ḥakam ibn Barjān (d. 627/1229) said that the overall message of the Qur’an is covered by knowledge of Allah’s names and attributes, prophethood and its proofs, and moral responsibility (taklīf) and its tests.</p>
                                       <p>Al-Burhān, of Al-Zarkashī, is considered a hallmark of ʿUlūm al-Qurʾān literature that set the tone for all subsequent scholarship. In this work, al-Zarkashī admitted that the Qur’anic sciences are uncountable and that the detailed synthesis of each science is an unattainable life-long endeavor. Hence, he limited his scope to the major principles of 47 sciences. Jalāl al-Dīn al-Bulqinī (d. 824/1316) authored Mawāqi’ al-ʿUlūm fī Mawāqi’ al-Nujūm in which he delineated 50 sciences. Al-Suyūṭī expanded on al-Bulqinī’s work in al-Taḥbīr fī ʿUlūm al-Tafsīr, where he extended the list to 101 sciences. The title of al-Taḥbīr is devoid of the term ʿUlūm al-Qurʾān; rather, it uses the term ‘exegetical sciences’ (ʿUlūm al-Tafsīr) to emphasize that the main objective of ʿUlūm al-Qurʾān, according to al-Suyūṭī, is assisting with the textual interpretation of the words of Allah.</p>
                                       <p>Afterward, al-Suyūṭī authored the field’s most cited work, al-Itqān fī ʿUlūm al-Qurʾān. He settled for the title of ʿUlūm al-Qurʾān and limited the number of its sciences to 80.27 Every one of those sciences, according to al-Suyūṭī, is worthy of individual treatment28 and their amalgamation subsumes more than 300 sciences.29 Al-Suyūṭī added that most of these sciences attracted scholarship individually dedicated to a particular science and that he reviewed much of this literature.30 Expanding on al-Itqān, Ibn ʿAqīlah al-Makkī (d. 1150/1737) authored al-Ziādah wal Iḥsān fī ʿUlūm al-Qurʾān and extended al-Suyūṭī’s list to 154 sciences. Al-Makkī devised new sciences and sub-disciplines, the total of which, according to him, may exceed 400 topics.31 Concomitant and subsequent works shifted to thematically covering ʿUlūm al-Qurʾān topics without much emphasis on the number of the sciences or the designation of areas of study.</p>

                                    </div>
                                </Grid>

                            </Grid>

                        </>
                    }
                />



            </div>

            <Footer />
        </div>

    );
}


export default AnIntroduction;