import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import BannerTwo from '../BannerTwo'
import NavBar from '../NavBar'
import SalahAndCtaSection from '../SalahAndCtaSection'
import FeaturedEvents from '../FeaturedEvents'
import FeaturedReads from '../FeaturedReads'
import SupportUs from '../SupportUs'
import FeaturedVideos from '../FeaturedVideos'
import FeaturedServices from '../FeaturedServices'
import Calendar from '../Calendar'
import AverageSection from '../AverageSection'
import PhotoGallery from '../PhotoGallery'
import AcademicProgramsHome from '../AcademicProgramsHome'
import Annoucement from '../Annoucement'

import '../../assets/css/style.css'
import '../../assets/css/responsive.css'

function HomePage(props) {



  return (

    <div>
      <Annoucement />
      <Header />
      <BannerTwo />
      <NavBar />
      <SalahAndCtaSection />
      <FeaturedEvents />
      <FeaturedReads />
      <SupportUs />
      <FeaturedVideos />
      <FeaturedServices />
      <AcademicProgramsHome />
       <PhotoGallery />
      {/* <Calendar /> */}
      <AverageSection />
      <Footer />
    </div>

  );
}


export default HomePage;