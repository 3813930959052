import React from 'react';
import { PageTitleTwo } from "master-react-lib";
import backgroundPattern from "../assets/imgs/PageHeaderBG.svg";

function PageTitle(props) {

    const breadcrumbsData = [

        { page: "HOME", link: "/" },

    ];


    return (
        <PageTitleTwo
            Title={props.Title}
            color="#B9278A"
            backgroundcolor="#F5F7FC"
            backgroundPattern={backgroundPattern}
            height="auto"
            breadcrumbsData={breadcrumbsData}
            currentPage={props.Path}
        />
    );
}


export default PageTitle;