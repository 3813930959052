import React from "react";
import Grid from '@material-ui/core/Grid';



function AverageSection() {




    return (
        <div className='AverageSection'>

            <div className='Container'>
                <Grid container spacing={3}>

                    <Grid item md={4} xs={12}>
                        <div className="AverageCard">
                            <h3>Average Musalli</h3>
                            <h2>10,000+</h2>
                            <p>Over 10,000+ Muslims pray Salah in theAbrar masjid everyday.</p>
                        </div>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <div className="AverageCard">
                            <h3>Average Students</h3>
                            <h2>1,000+</h2>
                            <p>We are teaching more than 1,000 students in different classes.</p>
                        </div>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <div className="AverageCard">
                            <h3>Average Donation</h3>
                            <h2>$100,000+</h2>
                            <p>Over $100,000+ are collected from generous donors each year.</p>
                        </div>
                    </Grid>

                </Grid>
            </div>

        </div>
    );
}


export default AverageSection;