import React from 'react';


function ADS() {




    return (
            // <a className='adsCard' href='#'><p>ADS <span>250 x 250</span></p></a>
            <></>
    );
}


export default ADS;