import React, { useState } from "react";
import { Section, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import { TitleOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';


import AcademicPrograms from './AcademicPrograms';

function AcademicProgramsHome() {

    const breadcrumbsData = [

        { page: "HOME", paht: "/" },

    ];
    const [isOpen, setOpen] = useState(false);


    return (

        <div className="AcadProgHome">
            <Section
                //background="#fff"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12}>
                            <TitleOne
                                TitleBold="Academic Programs"

                                //lineColor="#0053A5"
                                TitleBoldColor="#fff"

                                Button={true}
                                buttonLebel="APPLY NOW"
                                Link=""
                                //target="blank"

                                arrowIcon={<ArrowForwardRoundedIcon />}
                                arrowStatus={true}
                                arrowColor="#fff"

                                buttoncolor="#B9278A"
                                width="auto"
                                height="48px"
                                border="#B9278A 1px solid"
                                borderRadius="4px"
                                colorlabel="#fff"
                                fontWeight="600"
                                textAlign="center"

                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <AcademicPrograms />
                        </Grid>
                    </Grid>

                }
            />
        </div>



    );
}


export default AcademicProgramsHome;