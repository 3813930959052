import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import AcademicSection from '../AcademicSection'




import '../../assets/css/style.css'
import '../../assets/css/responsive.css'




function WeekendSchool(props) {



  return (

    <div>
      <Header />
      <NavBar />
      <AcademicSection />
      <Footer />
    </div>

  );
}


export default WeekendSchool;