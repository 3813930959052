import React from 'react';
import Grid from '@material-ui/core/Grid';

import ShahidKaiser from '../assets/imgs/ShahidKaiser.png';
import YassinTaoufik from '../assets/imgs/YassinTaoufik.png';
import KhalidAoukach from '../assets/imgs/KhalidAoukach.png';

function BoardOfDirectors() {




    return (
        <Grid container spacing={2}>

            <Grid item md={4} xs={12}>
                <div className='DirectorCard'>
                    <div className='DirectorCardImg'>
                        <img src={ShahidKaiser} />
                    </div>
                    <h1>Shahid Kaiser</h1>
                    <p>Executive Director & Imam</p>
                </div>

            </Grid>

            <Grid item md={4} xs={12}>
                <div className='DirectorCard'>
                    <div className='DirectorCardImg'>
                        <img src={YassinTaoufik} />
                    </div>
                    <h1>Yassine Taoufik</h1>
                    <p>Chairman</p>
                </div>

            </Grid>

            <Grid item md={4} xs={12}>
                <div className='DirectorCard'>
                    <div className='DirectorCardImg'>
                        <img src={KhalidAoukach} />
                    </div>
                    <h1>Khalide Aoukach</h1>
                    <p>Treasurer</p>
                </div>

            </Grid>
        </Grid>
)}


export default BoardOfDirectors;