import React from 'react';
import { Section, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import PageTitle from "../PageTitle";
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'


function TermsofService() {



    return (
        <div className='TosContainer'>
            <Header />
            <NavBar />
            <PageTitle
                Title="Terms of Service"
                Path="Terms of Service"
            />
            <Section
                background="#fff"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <div>
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>

                                <h1>Terms of Service <span>LAST UPDATED: MAR 12, 2022</span></h1>
                                

                                <h2>Terms of Use</h2>
                                <p>This website is intended for use as an information portal. By accessing this website and the materials
                                    contained herein, you acknowledge that you have read, understand, accept and agree to be bound by the
                                    following terms and conditions.</p>

                                <h2>Linked URLs</h2>
                                <p>Users are advised that information on sites linking to or from deenmap.com is not controlled or approved by DEENMAP.
                                    We offer no warranties, expressed or implied, as to the accuracy or completeness of information hosted on other sites.
                                    DEENMAP will not, under any circumstances, accept any liability in regard to the information contained on external websites,
                                    nor can we guarantee the security of any information you share with third-party websites.</p>

                                <h2>Copyright</h2>
                                <p>The content featured on the DEENMAP website is protected by copyright. All rights in the pages,
                                    site content and arrangement are owned by DEENMAP or its licensors. Users are prohibited from reproducing,
                                    modifying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works
                                    or using any site content for any purpose.</p>

                                    <h2>Liability</h2>
                                <p>While DEENMAP uses reasonable means to include accurate and current information on our website, errors and 
                                    omissions may occur. DEENMAP expressly disclaims any liability, whether in contract, tort, strict liability 
                                    or otherwise, for any indirect, incidental, consequential, punitive or special damages arising out of or in 
                                    any way connected with your access to or use of this site, and/or any other DEENMAP companies’ websites 
                                    whether or not DEENMAP is aware of the possibility of such damages. All information featured on this site, 
                                    including but not limited to graphics, text and links to other sites, is provided on an “as is” basis and 
                                    is as such subject to change without prior notice. Such information is provided, to the fullest extent 
                                    permissible pursuant to applicable law, without warranty of any kind express or implied, including 
                                    but not limited to implied warranties of merchantability, fitness for a particular purpose,
                                    non-infringement and freedom from computer viruses or similar malicious software. DEENMAP does not warrant 
                                    the adequacy, accuracy or completeness of any information on our site and expressly disclaims any liability 
                                    for errors or omissions therein. Visitors are responsible for evaluating the accuracy, completeness or usefulness 
                                    of any information or other content available on this site. DEENMAP does not warrant the error-free and/or 
                                    uninterrupted service of our site.</p>

                                    <h2>Privacy</h2>
                                    <p>DEENMAP will work to ensure that the processing of your personal information is carried out in such a manner 
                                        that your privacy is reasonably protected and safeguarded. For more privacy information, consult DEENMAP’s 
                                        Privacy Policy <a href="#">here.</a></p>

                            </Grid>

                        </Grid>



                    </div>
                }



            />

            <Footer />
        </div>
    );
}


export default TermsofService;