import React, { useState } from "react";
import { Section, ButtonOne, VideoCardOne, VideoPlayerModal } from "master-react-lib";
import backgroundPattern from "../assets/imgs/PageHeaderBG.svg";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Title from './Title';
import resourceIcon from '../assets/imgs/resourcesIcon.png';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';


// import ImageOne from '../assets/imgs/ImgOne.png';
// import ImageTwo from '../assets/imgs/ImgTwo.png';
// import ImageThree from '../assets/imgs/ImgThree.png';
import AcademicPrograms from './AcademicPrograms';
import PageTitle from "./PageTitle";


import ImageOne from "../assets/imgs/videos/sddefault.jpg";
import ImageTwo from "../assets/imgs/videos/video-two.jpg";
import ImageThree from "../assets/imgs/videos/video-three.jpg";



function AcademicSection() {


    const [isOpen, setOpen] = useState(false);
    const [isOpen_2, setOpen_2] = useState(false);
    const [isOpen_3, setOpen_3] = useState(false);


    return (
        <div className='ResourceSection'>

            <PageTitle
                Title="Academic Programs"
                Path="Academic Programs"
            />

            <div className='FeaturedEvents'>
                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="80px"
                    Container={
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>
                                <div className='PageTitleSection'>
                                    <h2>Unlock new skills with a structured curriculum</h2>
                                    <p>The Weekend School of the Abrar Masjid is committed to providing quality Islamic Education according to the Quran and the
                                        Sunnah of the Prophet Mohammed (Peace Be upon Him) to a diverse student population in the greater area.</p>
                                </div>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <AcademicPrograms />
                            </Grid>
                        </Grid>

                    }
                />

                <Section
                    background="#F5F7FC"
                    //ContainerWidth=""
                    Spacing="80px"
                    Container={
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>
                                <Title
                                    Title="Helpful Resources"
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button href="https://yaqeeninstitute.org/infographics/quranic-recitations-gods-miraculous-speech-infographic" target="_blank">VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button href="https://yaqeeninstitute.org/infographics/living-by-judgment-days-promise-infographic" target="_blank">VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            {/* <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className='ResourceCard'>
                                    <div className='Rc_letf'>
                                        <img src={resourceIcon} />
                                    </div>
                                    <div className='Rc_Right'>
                                        <h2>Playing the Taqiyya Card: <span>Evading Intelligent Debate by...</span></h2>
                                        <Button>VIEW DETAILS <LaunchRoundedIcon /></Button>
                                    </div>
                                </div>
                            </Grid> */}

                            {/* <Grid item md={12} xs={12}>
                                <ButtonOne
                                    label="SEE MORE"
                                    link=""
                                    target="_blank"

                                    arrowIcon={<ExpandMoreRoundedIcon />}
                                    arrowStatus={true}
                                    arrowColor="#DB9E30"

                                    buttoncolor="#fff"
                                    width="100%"
                                    height="50px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    color="#DB9E30"
                                    fontWeight="600"
                                    textAlign="center"
                                />
                            </Grid> */}


                        </Grid>



                    }
                />

                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="80px"
                    Container={
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>
                                <Title
                                    Title="Videos"
                                />
                            </Grid>

                            <Grid item md={4} xs={12} onClick={() => setOpen(true)}>
                                <VideoCardOne
                                    img={ImageOne}
                                    title="TheAbrar Masjid needs you !"
                                    //link="#"
                                    titleColor="#0A2540"
                                    border="border"
                                    icon={<SubscriptionsIcon />}
                                    ImgHeight="196px"
                                />
                            </Grid>

                            <Grid item md={4} xs={12} onClick={() => setOpen_2(true)}>
                                <VideoCardOne
                                    img={ImageTwo}
                                    title="TheAbrar masjid : a 4 years old muslim girl is confused between Mosque and Church ! PLEASE HELP"
                                    //link="#"
                                    titleColor="#0A2540"
                                    border="border"
                                    icon={<SubscriptionsIcon />}
                                    ImgHeight="196px"
                                />
                            </Grid>

                            <Grid item md={4} xs={12} onClick={() => setOpen_3(true)}>
                                <VideoCardOne
                                    img={ImageThree}
                                    title="TheAbrar Ramadan Children Celebration / Saturday May 12, 2018"
                                    //link="#"
                                    titleColor="#0A2540"
                                    border="border"
                                    icon={<SubscriptionsIcon />}
                                    ImgHeight="196px"
                                />
                            </Grid>

                            {/* <Grid item md={12} xs={12}>
                                <ButtonOne
                                    label="SEE MORE"
                                    link=""
                                    target="_blank"

                                    arrowIcon={<ExpandMoreRoundedIcon />}
                                    arrowStatus={true}
                                    arrowColor="#DB9E30"

                                    buttoncolor="#F5F7FC"
                                    width="100%"
                                    height="50px"
                                    border="#ccc 0px solid"
                                    borderRadius="4px"
                                    color="#DB9E30"
                                    fontWeight="600"
                                    textAlign="center"
                                />
                            </Grid> */}


                        </Grid>





                    }



                />


                <VideoPlayerModal
                    vidoeID="V87FCuQhUcQ"
                    isOpen={isOpen}
                    onClose={() => setOpen(false)}
                />

                <VideoPlayerModal
                    vidoeID="GBdV8DSOOeE"
                    isOpen={isOpen_2}
                    onClose={() => setOpen_2(false)}
                />

                <VideoPlayerModal
                    vidoeID="KtigYrH0_g8"
                    isOpen={isOpen_3}
                    onClose={() => setOpen_3(false)}
                />


            </div>

        </div>
    );
}


export default AcademicSection;