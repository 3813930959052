import { React, useState, useEffect } from "react";
import { Section, CardEleven } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import ADS from "./ADS";

import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import { getNews } from "../backend.js";

import ReadImgOne from '../assets/imgs/reads/read-img-one.png';
import ReadImgTwo from '../assets/imgs/reads/read-img-two.png';
import ReadImgThree from '../assets/imgs/reads/read-img-three.png';

function FeaturedReads() {
  const[reads, setReads] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getNews();

        let data = response?.data?.body?.data ?? [];

        data = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" && featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        data = data.sort((a, b) => a.article.order - b.article.order);

        let reads = data.map((item) => {
          return {
            id: item._id,
            image: item.article.imageMetaData.imageThumbnail,
            title: item.article.title,
            link: `/reads-details/${item._id}`,
          };
        });

        setReads(reads);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    reads.length > 0 ? (<div className="FeaturedEvents">
      <Section
        background="#F5F7FC"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Title
                Title="Featured Reads"
                ButtonStatus={true}
                ButtonLabel="ALL READS"
                Link="/reads"
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <div className="ScrollHr">
                <Grid container spacing={3}>
                  {reads.map((read, index) => (
                    <Grid key={index} item md={4} sm={4} xs={4}>
                      <CardEleven
                        img={read.image}
                        ImgHeight="196px"
                        title={read.title}
                        link={read.link}
                        titleColor="#0B132B"
                        buttonLabel="READ MORE"
                        ReadmoreIcon={<ArrowForwardRoundedIcon />}
                        ReadmoreColor="#676C7B"
                        border="border"
                      />
                    </Grid>
                  ))}

                  {/* <Grid item md={4} sm={4} xs={4}>
                    <CardEleven
                      img={ReadImgOne}
                      ImgHeight="196px"
                      title="The Character of Prophet Muhammad ﷺ: The Proofs of..."
                      link="/the-character-of-prophet"
                      titleColor="#0B132B"
                      buttonLabel="READ MORE"
                      ReadmoreIcon={<ArrowForwardRoundedIcon />}
                      ReadmoreColor="#676C7B"
                      border="border"
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={4}>
                    <CardEleven
                      img={ReadImgTwo}
                      ImgHeight="196px"
                      title="The Divine Gift of Gratitude: The Secret of Happin..."
                      link="/the-divine-gift-of-gratitude"
                      titleColor="#0B132B"
                      buttonLabel="READ MORE"
                      ReadmoreIcon={<ArrowForwardRoundedIcon />}
                      ReadmoreColor="#676C7B"
                      border="border"
                    />
                  </Grid>

                  <Grid item md={4} sm={4} xs={4}>
                    <CardEleven
                      img={ReadImgThree}
                      ImgHeight="196px"
                      title="An Introduction to ‘Ulum-al-Qur’an..."
                      link="/an-introduction-to-ulum-al-quran"
                      titleColor="#0B132B"
                      buttonLabel="READ MORE"
                      ReadmoreIcon={<ArrowForwardRoundedIcon />}
                      ReadmoreColor="#676C7B"
                      border="border"
                    />
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
      />
    </div>) : <></>
  );
}

export default FeaturedReads;
