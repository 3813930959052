import React from 'react';
import { Section, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';

import SalahTable from './Salah'
import CtaCustomCard from './CtaCustomCard'
import CtaOneCard from './CtaOneCard'



function SalahAndCtaSection() {

   


    return (
        <div>

            <Section
                background="#F5F7FC"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <Grid container spacing={3}>
                        <Grid item md={4} sm={12} xs={12}>
                            <CtaCustomCard />
                        </Grid>
                        <Grid item md={3} sm={6}  xs={12}>
                           <CtaOneCard />
                        </Grid>
                        <Grid item md={5} sm={6}  xs={12}>
                            <SalahTable />
                        </Grid>
                    </Grid>
                }
            />


        </div>
    );
}


export default SalahAndCtaSection;