import React from 'react';
import { ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Logo from '../assets/imgs/dc-abrar-website-logo.png';

function CtaCustomCard() {




    return (
        <div className='CtaCustomCard'>
            <div className='CtaCustomCardLogo'><img src={Logo} /></div>
            <h2>Welcome to</h2>
            <h1>theAbrar Masjid</h1>

            <ButtonOne
                label="MAKE A DONATION"
                link="https://give.deenfund.com/theabrar"
                target="_blank"

                arrowIcon={<ArrowForwardRoundedIcon />}
                arrowStatus={true}
                arrowColor="#fff"

                buttoncolor="#DB9E30"
                width="100%"
                height="50px"
                border="#ccc 0px solid"
                borderRadius="4px"
                color="#fff"
                fontWeight="600"
            //textAlign="center"
            //disabled="disabled"
            />

        </div>
    );
}


export default CtaCustomCard;