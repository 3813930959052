import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Logo from '../assets/imgs/dc-abrar-website-logo.png';
import CountUp from 'react-countup';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';



function SupportUs(props) {




    return (
        <div className='SupportUsSection'>

            <div className='Container'>

                <Grid container spacing={3}>

                    <Grid item md={6} xs={12}>
                        <div className='SupportUsLeftCol'>
                            <img src={Logo} />
                            <h1>Support us, we need your help.</h1>
                            <p>May Allah bless you! Thank you. Those who (in charity) spend of their goods by night and by day, in secret and in public, 
                                have their reward with their Lord</p>
                            <ButtonOne
                                label="DONATE NOW"
                                link="https://give.deenfund.com/theabrar"
                                target="_blank"

                                arrowIcon={<ArrowForwardRoundedIcon />}
                                arrowStatus={true}
                                arrowColor="#fff"

                                buttoncolor="#B9278A"
                                //width="100%"
                                height="50px"
                                border="#ccc 0px solid"
                                borderRadius="4px"
                                color="#fff"
                                fontWeight="600"
                            />

                        </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        
                            <div className='SupportUsRighttCol'>

                                <Grid container spacing={3}>

                                    <Grid item md={6} sm={6}  xs={12}>
                                        <div className='percentageCard RedCard'>
                                            <label></label>
                                            <h2><CountUp delay={2} end={46} />%</h2>
                                            <p>Mosque</p>
                                        </div>
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className='percentageCard YellowCard'>
                                            <label></label>
                                            <h2><CountUp delay={2} end={68} />%</h2>
                                            <p>Expenses</p>
                                        </div>
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className='percentageCard YellowCard'>
                                            <label></label>
                                            <h2><CountUp delay={2} end={75} />%</h2>
                                            <p>Feed Hungry</p>

                                        </div>
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className='percentageCard RedCard'>
                                            <label></label>
                                            <h2><CountUp delay={2} end={46} />%</h2>
                                            <p>Mosque</p>
                                        </div>
                                    </Grid>

                                </Grid>

                            </div>
                       
                    </Grid>

                </Grid>
            </div>
        </div>
    );
}


export default SupportUs;