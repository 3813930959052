import axios from "axios";

const API_KEY = process.env.REACT_APP_ORGANIZATION_API_KEY;
const BASE_URL = process.env.REACT_APP_BASEURL_DEV_API;
const ORG_ID = process.env.REACT_APP_ORGANIZATION_ID;
const APP_NAME = "deenweb";

export function getAnnouncements() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getAnnouncements?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSalahs() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getSalahs?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEvents() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getEvents?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getNews() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getNews?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVideos() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getVideos?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getServices() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getServices?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSliders() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/dw-public/getSliders?org=${ORG_ID}`, {
        headers: {
          token: API_KEY,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postContactData(contactData) {
  
  const names = contactData.name.trim().split(/\s+/);

  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/contact`, {
        ...contactData,
        name: {
          firstName: names[0],
          lastName: names[1],
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrganization() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/dw-public/getOrganization?orgId=${ORG_ID}`, {
        headers: {
          token: API_KEY,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPhotos() {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_URL}/dw-public/getPhotos?orgId=${ORG_ID}&application=${APP_NAME}`,
        {
          headers: {
            token: API_KEY,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}