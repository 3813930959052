import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'
import Grid from '@material-ui/core/Grid';
import { SocialMediaOne, Section, ButtonOne } from "master-react-lib";
import Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import SocialSection from './SocialSection';

import Banner from '../../assets/imgs/reads/read-img-two.png';








function TheDivineGiftofGratitude(props) {


    return (

        <div>
            <Header />
            <NavBar />

            <div className='FeaturedEvents'>
                <Section
                    background="#fff"
                    //ContainerWidth=""
                    Spacing="20px"
                    Container={
                        <>
                            <Grid container spacing={3}>

                                <Grid item md={12} xs={12}>
                                    <SocialSection />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <div className="eventDetailsBanner">
                                        <img src={Banner} />
                                    </div>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3}>

                                <Grid item md={5} xs={12}>
                                    <div className="eventDetailsContetns">
                                        <h1>The Divine Gift of Gratitude: The Secret of Happiness in the Modern World</h1>
                                    </div>
                                </Grid>

                                <Grid item md={7} xs={12}>
                                    <div className="eventDetailsContetns">

                                        <p><strong>Introduction</strong> Writing in The Atlantic in an article titled “Gratitude without God,” Emma Green asked the following question in the context of the annual American holiday of Thanksgiving: “Gratitude is the animus of these secular rituals, but the object of the gratitude is unclear. If people aren't thanking God, who are they thanking? You can thank your grandma for making delicious pie, but who do you thank for the general circumstances of your life?”</p>
                                        <p>This simple yet profound question frames an enigma that many of us perhaps don’t even perceive as a problem. We generally agree that we should strive to inculcate gratitude. Historically speaking, it is even understood as a necessary virtue across cultures and religions, through the societal, psychological, and moral lenses we apply. All agree that gratitude is a commendable trait and a means to better quality of life, happiness, physical and emotional health and wellbeing.2 But while the benefits of gratitude are easily observed and extensively documented, its definition remains somewhat elusive. What exactly is gratitude, and how do we achieve it?</p>
                                        <p>That depends on who you ask. The secular, scientific perspective claims that gratitude exists without the need to recognize God as its ultimate source and benefactor. In other words, it has no moral, religious, or spiritual bearing, despite the fact that theologians of the past and present attribute its origins to religious belief and worship.</p>
                                        <p>Many of us would like to believe that we embody gratitude, or at least aspire to do so in our daily lives, despite the ups and downs we encounter. However, studies show that fewer of us today actually experience what it truly is to be grateful. No doubt, people are facing hardship and pain on many levels in these turbulent times. Nevertheless, instant gratification, and the momentary happiness and comfort associated with it rarely bring lasting contentment and gratitude. Current levels of discontent not only indicate a dangerous trend, but also beg the questions, Why is that so? Do our current circumstances play a role? And where is God in the equation? Modernity and secularism have at a bare minimum eroded ties to God, reducing religion to rituals and inherited cultural practice at best. Often, it is not even that, nothing more than a vague concept quite akin to wishful thinking in order to serve as a lubricant for our shallow lives.</p>
                                        <p>The impact of our societal immersion in materialism, consumerism, and enslavement to social media is self-evident—not to mention the imminent, potentially life-altering threats of artificial intelligence, virtual reality, and the metaverse.4 More than a century ago, French sociologist Emile Durkheim (d. 1917) was already writing about the “cult of the individual,”5 a diagnosis of modern society’s ills, further confirmed by contemporary research showing the rise of near-ubiquitous self-entitlement, narcissism, and “generation me vs. we” mindsets6 that have resulted in pervasive estrangement and dissatisfaction, despite living in material abundance.</p>
                                        <p>A closer examination of ourselves would reveal shukr as the missing key to the life of contentment we all naturally seek. Shukr is gratitude, but it’s also far more. It extends beyond the descriptions and prescriptions of gratitude defined within secular boundaries. It extends well beyond oneself or another as its object, to directing one’s gratitude foremost to God, the Almighty, the most Merciful and Generous, the One who grants us the gift of life, sustenance, and most of all, His guidance. Shukr is a religious obligation and therefore integral to our purpose.8 It is a powerful spiritual state emanating from deep within our hearts, expressed practically through acts of worship and obedience to God. Accordingly, it must also manifest in acts of generosity, kindness, and gratitude to others.</p>
                                        <p>Islam’s two sacred sources, the Qur’an and Sunnah, provide us divine truths about the realities around and within us, be they observable or imperceptible. They afford us profound clarity and guidance to the holistic prescription for life. Gratitude is among the central themes extensively addressed therein by the Maker of all of humanity. After all, who knows us better than the One who created and fashioned us? Hence, we look to the Qur’an and Sunnah for a complete understanding of gratitude—one that gives us a clear and practical path to implementation.</p>
                                        <p>The concept of shukr explains and reconciles what we already grasp intuitively, yet find difficult to achieve at times. It provides the motivation and tools to act on what is demanded of us by God, in return for the promise of attaining success and happiness. The objective of this paper is to evaluate and even challenge our understanding and practice of gratitude, in order to strengthen our faith, conviction, and practice. Our analysis will reveal the distinguishing and core aspects of shukr as a moral obligation, an act of worship, and a foundation for contentment in this world and in the Hereafter.</p>
                                        <p>Getting back to Thanksgiving for a moment, it is interesting that when he proclaimed it a national holiday in 1863, Abraham Lincoln himself chose to direct its observance toward God when he said:</p>
                                        <p>"The year that is drawing towards its close has been filled with the blessings of fruitful fields and healthful skies. To these bounties, which are so constantly enjoyed that we are prone to forget the source from which they come, others have been added, which are of so extraordinary a nature, that they cannot fail to penetrate and soften even the heart which is habitually insensible to the ever-watchful providence of Almighty God… No human counsel hath devised, nor hath any mortal hand worked out these great things. They are the gracious gifts of the Most High God who, while dealing with us in anger for our sins, hath nevertheless remembered mercy."</p>

                                    </div>
                                </Grid>

                            </Grid>

                        </>
                    }
                />



            </div>

            <Footer />
        </div>

    );
}


export default TheDivineGiftofGratitude;