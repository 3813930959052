import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


// import ImgOne from '../assets/imgs/programs/gallery-4-760x428.jpg';
//  import ImgTwo from '../assets/imgs/programs/gallery-1-760x428.jpg';
// import ImgThree from '../assets/imgs/programs/gallery-8-760x428.jpg';




function AcademicPrograms() {


    return (
        <Grid container spacing={3}>

            <Grid item md={4} sm={6} xs={12}>
                <div className='AcProgramCard'>
                    <div className='AcProgramImg'>
                       
                        <img src={require('../assets/imgs/programs/deenconnect-the-abrar-programs-islamic-school.jpg')} alt="DEENCONNECT-The Abrar Islamic School" />
                    </div>
                    <div className='AcProgramCardInfo'>
                        <div className='ProgDate'><label>March 16, 2021</label> <span>Free</span></div>
                        <h3>Islamic School</h3>
                        <Button>REGISTRATION DETAILS</Button>
                    </div>
                </div>
            </Grid>

            <Grid item md={4} sm={6}  xs={12}>
                <div className='AcProgramCard'>
                    <div className='AcProgramImg'>
                        {/* <img src={ImgTwo} /> */}
                        <img src={require('../assets/imgs/programs/deenconnect-the-abrar-youth-program.jpg')} alt="DEENCONNECT-The Abrar Children's Program" />

                    </div>
                    <div className='AcProgramCardInfo'>
                        <div className='ProgDate'><label>March 18, 2021</label> <span>Free</span></div>
                        <h3>Youth Program</h3>
                        <Button>REGISTRATION DETAILS</Button>
                    </div>
                </div>
            </Grid>

            <Grid item md={4} sm={6}  xs={12}>
                <div className='AcProgramCard'>
                    <div className='AcProgramImg'>
                        
                        <img src={require('../assets/imgs/programs/deenconnect-the-abrar-childrens-program.jpg')} alt="DEENCONNECT-The Abrar Youth Program" />

                    </div>
                    <div className='AcProgramCardInfo'>
                        <div className='ProgDate'><label>March 18, 2021</label> <span>Free</span></div>
                        <h3>Children’s Program</h3>
                        <Button>REGISTRATION DETAILS</Button>
                    </div>
                </div>
            </Grid>




        </Grid>
    );
}


export default AcademicPrograms;