import React from 'react';
import { CTAOne, ButtonOne } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

function CtaOneCard() {




    return (
        <div className='CtaOneCard'>
            <CTAOne
                backgroundColor="#FFFFFF"
                Title="Build a house of Allah, and Allah will build one for you in Jannah"
                SubTitle="Al-Tirmidhi"
                ButtonLebel="MAKE A DONATION"
                link="https://give.deenfund.com/theabrar"
                target="blank"
                minHeight=""

                Btnbackground="#B9278A"
                Btncolor="#fff"
                BtnPadding="18px 16px"
                arrowIcon={<ArrowForwardRoundedIcon />}
                buttonWidth=""

            />

            <div className='CtaButtons'>
                
                <ButtonOne
                    label="WHO WE ARE?"
                    link="/about-us"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#B9278A"

                    buttoncolor="#EDDEEE"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#B9278A"
                    fontWeight="600"
                />

                <ButtonOne
                    label="SERVICES WE PROVIDE"
                    link="/our-services"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#B9278A"

                    buttoncolor="#EDDEEE"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#B9278A"
                    fontWeight="600"
                />

                <ButtonOne
                    label="DONATE FOR SADAQAH"
                    link="https://give.deenfund.com/theabrar"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#B9278A"

                    buttoncolor="#EDDEEE"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#B9278A"
                    fontWeight="600"
                />

                <ButtonOne
                    label="DONATE FOR JAKAT"
                    link="https://give.deenfund.com/theabrar"
                    target="_blank"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#B9278A"

                    buttoncolor="#EDDEEE"
                    width="100%"
                    height="50px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#B9278A"
                    fontWeight="600"
                />
            </div>

        </div>
    );
}


export default CtaOneCard;