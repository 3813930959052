import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { SocialMediaOne, Section, ButtonOne } from "master-react-lib";
import Button from "@material-ui/core/Button";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

import Header from "../Header";
import Footer from "../Footer";
import NavBar from "../NavBar";

import FacebookIcon from "../../assets/imgs/socialicons/FacebookRounded.svg";
import TwitterIcon from "../../assets/imgs/socialicons/TwitterRounded.svg";
import WhatsAppIcon from "../../assets/imgs/socialicons/WhatsappRounded.svg";
import YouTubeIcon from "../../assets/imgs/socialicons/YouTubeRounded.svg";

import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Location from "../../assets/imgs/eventicons/location.svg";
import Zoom from "../../assets/imgs/eventicons/zoom.svg";
import Facebook from "../../assets/imgs/eventicons/facebook.svg";
import Youtube from "../../assets/imgs/eventicons/youtube.svg";
import Phone from "../../assets/imgs/eventicons/phone.svg";

import ShareRoundedIcon from "@material-ui/icons/ShareRounded";

import EventFlyer from "../../assets/imgs/events/Seminar_March2022.png";

import DelwarHossainone from "../../assets/imgs/ZamZam_Team_Imam_Delwar_Hossain.jpeg";
import HafizRayhanUddin from "../../assets/imgs/ZamZam_Team_Hafiz_Rayhan_Uddin.jpeg";
import ShaikhIsmailAlMakki from "../../assets/imgs/ZamZam_Team_Shaikh_Ismail_Al-Makki.jpg";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getEvents } from "../../backend.js";
import moment from "moment";

function ParseDescription(description) {
  let x = description && JSON.parse(description);
  return x[0]?.children[0]?.text ?? "";
}

function EventDetails(props) {
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const socialIconData = [
    {
      icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-facebook-icon.webp",
      link: "https://www.facebook.com/theAbrarNYC/",
    },
    // {
    //   icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-twitter-icon.webp",
    //   link: "https://www.facebook.com/",
    // },
    {
      icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-youtube-icon.webp",
      link: "https://www.youtube.com/channel/UCW31JSvDCszmIMcMvsiIKng/channels",
    },
  ];

  const { id } = useParams();
  const history = useNavigate();

  const [event, setEvent] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getEvents();

        let data = response?.data?.body?.data ?? [];

        data = data.filter(({ _id }) => _id === id);

        let event = data.map((item) => {
          let startDate = moment(item.event.eventStart.startAt)
            .utcOffset(0)
            .format("ddd, MMM DD");

          let startTime = moment(item.event.eventStart.startTime)
            .utcOffset(0)
            .format("h:mm a");

          let endTime = moment(item.event.endAt).utcOffset(0).format("h:mm a");

          const attendees = item.event.attendee;
          let ageGroups = [];
          let attendeesString = "";

          if (
            attendees.ageGroups.seniors &&
            attendees.ageGroups.adult &&
            attendees.ageGroups.youth &&
            attendees.ageGroups.children &&
            attendees.gender.male &&
            attendees.gender.female
          ) {
            attendeesString = "Any age-group can join";
          } else if (attendees.ageGroups.seniors) {
            ageGroups.push("Seniors");
          } else if (attendees.ageGroups.adult) {
            ageGroups.push("Adults");
          } else if (attendees.ageGroups.youth) {
            ageGroups.push("Youth");
          } else if (attendees.ageGroups.children) {
            ageGroups.push("Children");
          }

          for (const ageGroup of ageGroups) {
            attendeesString += `${ageGroup} ● `;
          }

          if (attendees.gender.male && attendees.gender.female) {
            attendeesString += ` [All Genders]`;
          } else if (attendees.gender.male) {
            attendeesString += ` [Male Only]`;
          } else {
            attendeesString += ` [Female Only]`;
          }

          return {
            title: item.event.title,
            date: startDate,
            time: `${startDate} AT ${startTime} EST`.toUpperCase(),
            fee:
              item.event.entry.fee === 0
                ? "Free"
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(item.event.entry.fee),
            attendees: attendeesString,
            image: item.event.imageDetails.imageUrl,
            description: ParseDescription(item.event.description),
            location: `${item.event.location.address.line1}, ${
              item.event.location.address.line2 !== ""
                ? `${item.event.location.address.line2},`
                : ""
            } ${item.event.location.address.city}, ${
              item.event.location.address.state
            }`,
            speakers: item.event.speakers.map((speaker) => {
              return {
                id: speaker.id,
                name: `${speaker.name.firstName} ${speaker.name.lastName}`,
                avatar: speaker.imageMetaData.imageUrl,
                contact: speaker.phone,
              };
            }),
          };
        });

        setEvent(event[0]);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);

  return (
    <div>
      <Header />
      <NavBar />

      <div className="EventDetailsModalContainer">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <div className="Socialsection">
              <div>
                <Button
                  className="backBtn"
                  onClick={() =>
                    history.previous ? history.goBack() : history("/events")
                  }
                  href="#"
                >
                  <ArrowBackRoundedIcon /> All Events
                </Button>
              </div>
              <div>
                <SocialMediaOne
                  copyBackground="#DB9E30"
                  copyColor="#fff"
                  socialIconData={socialIconData}
                  copyButton={true}
                  onClick={copyLinkToClipboard}
                />
              </div>
            </div>
          </Grid>

          <Grid item md={7} sm={7} xs={12}>
            <div className="DtlRightContainer">
              <h1>{event.title}</h1>
              <div className="DtlDate">{event.time}</div>

              <div className="DtlStatusCol">
                <li>
                  <AccessTimeRoundedIcon /> {event.date}
                </li>
                <li>
                  <VideocamRoundedIcon /> On-Site
                </li>
                <li>
                  <AttachMoneyRoundedIcon /> {event.fee}
                </li>
                <li>
                  <GroupRoundedIcon /> {event.attendees}
                </li>
              </div>

              <div className="SpeekerContainer Socialsection">
                <h1>Share On Social</h1>
                <SocialMediaOne
                  copyBackground="#DB9E30"
                  copyColor="#fff"
                  socialIconData={socialIconData}
                  copyButton={true}
                  target="_blank"
                  onClick={copyLinkToClipboard}
                />
              </div>

              <div className="SpeekerContainer">
                <h1>Speakers</h1>

                <Grid container spacing={1}>
                  {event.speakers?.map((item, index) => (
                    <Grid key={index} item md={12} sm={12} xs={12}>
                      <div className="Speekerchip">
                        <div className="SpeekerIcon">
                          <img src={item.avatar} alt="speaker's avatar" />
                        </div>
                        <div className="SpeekerDetails">
                          <h2>{item.name}</h2>
                          <p>{item.contact}</p>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>

              <div className="SpeekerContainer">
                <h1>Event Location</h1>

                <div className="accordionContainer">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordionTitle">
                        <img src={Location} alt="icon" /> Location/Venue
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{event.location}</Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordionTitle">
                        <img src={Zoom} alt="icon" /> Zoom
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      Link:{" "}
                      <a
                        href="https://us02web.zoom.us/j/86886474449?pwd=d2VXVU9DT214YzVqcTRLei8vSnUvdz09"
                        target="_blank"
                      >
                        https://us02web.zoom.us/j/86886474449?pwd=d2VXVU9DT214YzVqcTRLei8vSnUvdz09
                      </a>
                      <p>
                        Topic: The Hajj Pilgrimage and Its Significance in Islam
                      </p>
                      <p>
                        Topic: The Hajj Pilgrimage and Its Significance in Islam
                      </p>
                      <p>Time: Jan 22, 2022 02:00 PM Astana, Dhaka </p>
                      <p>Meeting ID: 780 1280 8408 </p>
                      <p>Passcode: Sx2DvH</p>
                    </AccordionDetails>
                  </Accordion> */}

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordionTitle">
                        <img src={Facebook} alt="icon" /> Facebook
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          href="https://www.facebook.com/theAbrarNYC/"
                          target="_blank"
                        >
                          Facebook/theAbrarNYC
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordionTitle">
                        <img src={Youtube} alt="icon" /> YouTube
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          href="https://www.youtube.com/channel/UCW31JSvDCszmIMcMvsiIKng/channels"
                          target="_blank"
                        >
                          YouTube/theAbrarNYC
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="accordionTitle">
                        <img src={Phone} alt="icon" /> Teleconference
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Unavailable</Typography>
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </div>
            </div>
          </Grid>

          <Grid item md={5} sm={5} xs={12}>
            <div className="ImgCol">
              <img className="dtlImg" src={event.image} alt="Event Flyer" />
              {/* <div className="ShareCol">
                <div className="SocialIcon">
                  <Link
                    href="https://www.facebook.com/ZamzamHajjUSA/"
                    target="_blank"
                  >
                    <img
                      src={FacebookIcon}
                      alt="Logo"
                      className="ZamzamLogoSmaill"
                    />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw"
                    target="_blank"
                  >
                    <img
                      src={YouTubeIcon}
                      alt="Logo"
                      className="ZamzamLogoSmaill"
                    />
                  </Link>
                  <Link>
                    <img
                      src={WhatsAppIcon}
                      alt="Logo"
                      className="ZamzamLogoSmaill"
                    />
                  </Link>
                  <Link className="ShareIcon">
                    Share | <ShareRoundedIcon />
                  </Link>
                </div>
              </div> */}
            </div>

            <div className="DtlContentLeft">
              <h2>Event Description</h2>
              <p>{event.description}</p>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  );
}

export default EventDetails;
