import { React, useState, useEffect } from "react";
import { Section, VideoCardOne } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";

import SubscriptionsIcon from "@material-ui/icons/Subscriptions";

import { getServices } from "../backend.js";

import ImageOne from "../assets/imgs/NikahIllustration.svg";
import ImageTwo from "../assets/imgs/JanazahIllustration.svg";
import ImageThree from "../assets/imgs/HajjIllustration.svg";
import ImageFour from "../assets/imgs/DuahIllustration.svg";

function FeaturedServices() {
  // Commented by Hussain.
  // const [services, setServices] = useState([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await getServices();

  //       let data = response?.data?.body?.data ?? [];

  //       data = data.filter(
  //         ({ status, featured, publishTo, publishFrom }) =>
  //           status === "Public" && featured &&
  //           new Date(publishTo) > new Date() &&
  //           new Date(publishFrom) < new Date()
  //       );

  //       let services = data.map((item) => {
  //         return {
  //           id: item._id,
  //           image: item.service.imageMetaData.imageThumbnail,
  //           imageName: item.service.imageMetaData.imageName,
  //           name: item.service.name,
  //           description: item.service.description,
  //         };
  //       });

  //       setServices(services);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getData();
  // }, []);

  return (
    <div className="FeaturedEvents">
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Title
                Title="Featured Services"
                ButtonStatus={true}
                ButtonLabel="ALL SERVICES"
                Link="/our-services"
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <div className="ScrollHr">
                <Grid container spacing={3}>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="FeaturedServicesCard">
                      <img src={ImageOne} />
                      <h2>NIKAH</h2>
                      <p>Give yourself into the bond of a lifetime</p>
                    </div>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <div className="FeaturedServicesCard">
                      <img src={ImageTwo} />
                      <h2>JANAJAH</h2>
                      <p>Bid the departed with the purest of farewells</p>
                    </div>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <div className="FeaturedServicesCard">
                      <img src={ImageThree} />
                      <h2>HAJJ & UMRAH</h2>
                      <p>Get yourself another step closer to you Creator</p>
                    </div>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <div className="FeaturedServicesCard">
                      <img src={ImageFour} />
                      <h2>DOAH</h2>
                      <p>A conversation between you and your Lord</p>
                    </div>
                  </Grid>
                </Grid>
              </div>

            </Grid>


          </Grid>
        }
      />
    </div>
  );
}

export default FeaturedServices;
