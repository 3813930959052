import React from 'react';
import { Section, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import PageTitle from "../PageTitle";

import Header from '../Header'
import Footer from '../Footer'
import NavBar from '../NavBar'


function PrivacyPolicy() {


    return (
        <div className='TosContainer'>
            <Header />
            <NavBar />

            <PageTitle
                Title="Privacy Policy"
                Path="Privacy Policy"
            />

           
            <Section
                background="#fff"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <div>
                        <Grid container spacing={3}>

                            <Grid item md={12} xs={12}>

                                <h1>Privacy Policy <span>LAST UPDATED: MAR 12, 2022</span></h1>

                                <p>These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENFUND (“Effective Date”). This Agreement governs the relationship between DEENFUND, INC. a New York Corporation, 77 Broad St. New York, NY 10004 (“DEENFUND,” the “Company,” “we,” “our,” or “us”), and you the account holder, user, or licensee (“You” or “User) (collectively, the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, or through our website.</p>
                                <p> Please read the Agreement carefully before you start to use the Website. By using the Website, or by clicking to “accept” or “agree” to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy, incorporated herein by reference. You also acknowledge and warrant, if applicable, that you have the authority to enter into this agreement on behalf of, and bind, the entity for which you will be using the Platform as a registered user. </p>
                                <p>If you do not want to agree to these <a href="#">Terms of Use </a>or the <a href="#">Privacy Policy</a> you must not access or use the Website.</p>

                                <h2>1 - Grant of Rights</h2>
                                <p>Careful now. Design Sprints definitely speed things up considerably, but they are not about building a finished product within one week. If you go into your first Sprint with the expectation of having usable code or sending off files to a printer or manufacturer at the end of the week, you are doing it wrong.</p>
                                <p>(i) Use, in the form in which such Platform is delivered by the DEENFUND only <br></br>(ii) Utilize such Platform only for the purpose of receiving donations.</p>
                                <p>Limited Rights of License: Your obtainment and/or use of the Platform does not grant you any rights of ownership in the Platform, as all rights granted are merely those of a licensee under the terms of this Agreement, with those rights granted only for such time as:</p>
                                <p>a. All trade secrets, and all trade secret rights and equivalent rights arising<br></br> b. All works of authorship, including all registered and unregistered copyrights <br></br>d. As the same may arise or exist under common law, state law, federal law and laws of foreign countries (collectively “Intellectual Property Rights”).</p>

                                <h2>2 - Payments</h2>
                                <p>Transaction Fee: The fees owed with respect to the rights and licenses granted hereunder shall be no more than 4 percent (%4.000) per unique credit card donation. A bill will be created once a month with those aggregate fees. These fees do not include Stripe’s processing fees, which will also be deducted immediately at the time of the transaction processing.</p>
                                <p>Taxes: The fees charged do not include any foreign, federal, state or local sales, use or other similar taxes, however designated, levied against the sale, licensing, delivery or use of the Platform. You agree to pay, or reimburse, DEENFUND for all such taxes imposed, provided, however, that you shall not be liable for any taxes based on DEENFUND net income. You agree to promptly notify us of any amendment or revocation of such certificate, and will hold DEENFUND harmless from and against any taxes or other monies resulting from the failure to hold a certificate recognized as valid by the appropriate tax authority.</p>
                                <h2>3 - Terms and Termination</h2>
                                <p>Termination. DEENFUND may terminate your access to all or any part of the Website at any time, with or without cause, with or without
                                    notice, effective immediately. If you wish to terminate this Agreement or your TopNonprofits.com account (if you have one), you may
                                    simply discontinue using the Website. DEENFUND can terminate the Website immediately as part of a general shut down of our service.
                                    All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation,
                                    ownership provisions, warranty disclaimers, indemnity and limitations of liability. <br></br> <br></br>Without limiting the foregoing,
                                    you agree to not use the Services to establish or contribute to any Organizations with the implicit or express purpose relating to any of
                                    the following:</p>

                                <p><strong>Productivity: </strong>They offer a productive process that allows to experiment with solutions instead of procrastinating forever.</p>
                                <p><strong>Low risk: </strong>They offer a self-contained space where failure carries a low risk, no stigma and thus help propagate an experimental mindset: try things and see if they work instead of racking your brain trying to make the one, right decision (which is impossible).</p>
                                <p>They compress the time needed from conceptualizing solutions to testing them into a few days.</p>
                                <h2>4 - Disclaimer of Warranties. </h2>
                                <p>The Website is provided “as is”. DEENFUND and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied,
                                    including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither DEENFUND nor
                                    its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted.
                                    You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.</p>

                                <p>1. What do different age groups want from ads? <br></br>2. What are the most effective kinds of media? <br></br>3. Why is online attention so expensive? <br></br>4. How can brands be certain their ads are driving consumer behavior?</p>
                                <h2>5 - Intellectual Property Notices and Confidentiality</h2>
                                <p>Declining viewership means the death of TV is close – or at least, that’s what some commentators would have us believe. But even in this post-COVID digital age, broadcast TV remains more popular than its online alternative.
                                    <br></br> <br></br>But it’s not just its popularity that makes TV so appealing to advertisers, it’s the way in which it provokes an emotional response from consumers.</p>


                            </Grid>

                        </Grid>



                    </div>
                }



            />

            <Footer />
        </div>
    );
}


export default PrivacyPolicy;