import { React, useState, useEffect } from "react";

function Donate(){
    useEffect(() => {
        const timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace('https://give.deenfund.com/theabrar');
        }, 30);
    
        return () => clearTimeout(timeout);
      }, []);
    
      return <>Will redirect in 3 seconds...</>;
}

export default Donate ; 