import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  VideoCardOne,
  Section,
  CardSixteen,
  ButtonOne,
  VideoPlayerModal,
} from "master-react-lib";
import EventImgOne from "../assets/imgs/ImgThree.png";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";
import PageTitle from "./PageTitle";

import ImageOne from "../assets/imgs/videos/sddefault.jpg";
import ImageTwo from "../assets/imgs/videos/video-two.jpg";
import ImageThree from "../assets/imgs/videos/video-three.jpg";

import { getVideos } from "../backend.js";

function YouTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

function VideosSection() {
  const [isOpen, setOpen] = useState(false);
  const [isOpen_2, setOpen_2] = useState(false);
  const [isOpen_3, setOpen_3] = useState(false);

  const [firstFeaturedVideo, setFirstFeaturedVideo] = useState(null);
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [moreVideos, setMoreVideos] = useState([]);

  const [modalVideoid, setmodalVideoId] = useState();

  function openVideoModal(e, videoId) {
    setmodalVideoId(videoId);

    setOpen(true);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getVideos();

        let data = response?.data?.body?.data ?? [];

        let featuredVideos = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" &&
            featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        let moreVideos = data.filter(
          ({ status, featured, publishTo, publishFrom }) =>
            status === "Public" && !featured &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        featuredVideos = featuredVideos.map((item) => {
          const videoID = YouTubeGetID(item.video.link);

          return {
            id: item._id,
            videoID: videoID,
            image: videoID !== "" ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg` : ImageOne,
            title: item.video.title,
            link: item.video.link,
          };
        });

        moreVideos = moreVideos.map((item) => {
          const videoID = YouTubeGetID(item.video.link);

          return {
            id: item._id,
            videoID: videoID,
            image:
              videoID !== ""
                ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
                : ImageOne,
            title: item.video.title,
            link: item.video.link,
          };
        });

        setFirstFeaturedVideo(featuredVideos.shift());
        setFeaturedVideos(featuredVideos);
        setMoreVideos(moreVideos);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  //   const eventsData = [
  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },

  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },

  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },

  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },

  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },

  //     {
  //       img: EventImgOne,
  //       title:
  //         "Mawālī: How Freed Slaves and Non-Arabs Contributed to Islamic Scholarship",
  //       link: "",
  //     },
  //   ];

  return (
    <div className="FeaturedEvents">
      <PageTitle Title="Videos" Path="Videos" />
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <>
            <Grid container spacing={3} style={{ marginBottom: "80px" }}>
              {firstFeaturedVideo && (
                <Grid item md={8} xs={12} onClick={(e) => openVideoModal(e, firstFeaturedVideo.videoID)}>
                  <VideoCardOne
                    img={firstFeaturedVideo.image}
                    ImgHeight="470px"
                    title={firstFeaturedVideo.title}
                    FontSize="40px"
                    TitleSpace="48px"
                    titleColor="#0B132B"
                    border="border"
                    icon={<SubscriptionsRoundedIcon />}
                  />
                </Grid>
              )}

              {featuredVideos.length > 0 && (
                <Grid item md={4} xs={12}>
                  <Grid container spacing={3}>
                    {featuredVideos.map((item, index) => (
                      <Grid
                        key={item.id}
                        item
                        md={12}
                        xs={12}
                        onClick={(e) => openVideoModal(e, item.videoID)}
                      >
                        <VideoCardOne
                          img={item.image}
                          ImgHeight="196px"
                          title={item.title}
                          titleColor="#0A2540"
                          border="border"
                          icon={<SubscriptionsRoundedIcon />}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <div className="AboutContents">
                  <h2>More Videos</h2>
                </div>
              </Grid>

              {moreVideos.map((item, index) => (
                <Grid key={item.id} item md={6} xs={12} onClick={(e) => openVideoModal(e, item.videoID)} >
                  <div className="ListingVideoCard">
                    <CardSixteen
                      image={item.image}
                      title={item.title}
                      buttonText="READ MORE"
                      
                      readmoreColor="#DB9E30"
                      playicon={<SubscriptionsRoundedIcon />}
                    />
                  </div>
                </Grid>
              ))}

              <Grid item md={12} xs={12}>
                <ButtonOne
                  label="LOAD MORE"
                  link=""
                  target="_blank"
                  arrowIcon={<ExpandMoreRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#DB9E30"
                  buttoncolor="#F5F8FC"
                  width="100%"
                  height="50px"
                  border="#ccc 0px solid"
                  borderRadius="4px"
                  color="#DB9E30"
                  fontWeight="600"
                  textAlign="center"
                />
              </Grid>
            </Grid>

            <VideoPlayerModal
              vidoeID={modalVideoid}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
            />
          </>
        }
      />
    </div>
  );
}

export default VideosSection;
