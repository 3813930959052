import React from 'react';
import { AccordionOne } from "master-react-lib";
import Grid from '@material-ui/core/Grid';

//  import ImageOne from '../assets/imgs/deenconnect-the-abrar-nikah-illustration.svg';

 import Imageone from '../assets/imgs/NikahIllustration.svg';

import ImageTwo from '../assets/imgs/JanazahIllustration.svg';
import ImageThree from '../assets/imgs/HajjIllustration.svg';
import ImageFour from '../assets/imgs/DuahIllustration.svg';

function ServicesCommunity() {


    const [expanded, setExpanded] = React.useState('1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (

        <div>
            <AccordionOne
                title="Nikah"
                panel="1"
                expanded={expanded}
                handleChange={handleChange}

                ////// Styling ////////

                titleColor="#0B132B"
                titleFontSize="16px"
                TitleFontWeight="600"
                iconStatus={false}

                AccordionBody={
                    <Grid container spacing={2}>

                        <Grid item md={4} xs={12}>
                            <div className='RoundedImg'>
                            <img src={Imageone} />
                               {/* < img src={require("../assets/imgs/deenconnect-the-abrar-nikah-illustration.svg")} 
                                alt="DEENCONNECT- The Abrar Nikah Illustration "/> */}
                                </div>

                        </Grid>

                        <Grid item md={8} xs={12}>
                            <div className='CommunityText'>
                                <p>Give yourself into the bond of a lifetime</p>
                            </div>

                        </Grid>


                    </Grid>
                }

            />

            <AccordionOne
                title="Janazah"
                panel="2"
                expanded={expanded}
                handleChange={handleChange}

                ////// Styling ////////

                titleColor="#0B132B"
                titleFontSize="16px"
                TitleFontWeight="600"
                iconStatus={false}

                AccordionBody={
                    <Grid container spacing={2}>

                        <Grid item md={4} xs={12}>
                            <div className='RoundedImg'>
                                <img src={ImageTwo} />
                            </div>

                        </Grid>

                        <Grid item md={8} xs={12}>
                            <div className='CommunityText'>
                                <p>Bid the departed with the purest of farewells</p>
                            </div>

                        </Grid>


                    </Grid>
                }

            />

            <AccordionOne
                title="Hajj & Umrah"
                panel="3"
                expanded={expanded}
                handleChange={handleChange}

                ////// Styling ////////

                titleColor="#0B132B"
                titleFontSize="16px"
                TitleFontWeight="600"
                iconStatus={false}

                AccordionBody={
                    <Grid container spacing={2}>

                        <Grid item md={4} xs={12}>
                            <div className='RoundedImg'>
                                <img src={ImageThree} />
                            </div>

                        </Grid>

                        <Grid item md={8} xs={12}>
                            <div className='CommunityText'>
                                <p>Get yourself another step closer to you Creator</p>
                            </div>

                        </Grid>


                    </Grid>
                }

            />

            <AccordionOne
                title="Duah"
                panel="4"
                expanded={expanded}
                handleChange={handleChange}

                ////// Styling ////////

                titleColor="#0B132B"
                titleFontSize="16px"
                TitleFontWeight="600"
                iconStatus={false}

                AccordionBody={
                    <Grid container spacing={2}>

                        <Grid item md={4} xs={12}>
                            <div className='RoundedImg'>
                                <img src={ImageFour} />
                            </div>

                        </Grid>

                        <Grid item md={8} xs={12}>
                            <div className='CommunityText'>
                                <p>A conversation between you and your Lord</p>
                            </div>

                        </Grid>


                    </Grid>
                }

            />

        </div>




    )
}


export default ServicesCommunity;