import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import {
  VideoCardOne,
  Section,
  CardSixteen,
  ButtonOne,
  VideoPlayerModal,
  SocialMediaOne,
} from "master-react-lib";

import Button from "@material-ui/core/Button";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

import banner from "../assets/imgs/ImgTwo.png";
import ImageOne from "../assets/imgs/videos/sddefault.jpg";

import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";
import { useNavigate, useParams } from "react-router-dom";
import { getNews } from "../backend";

function ParseDescriptions(description) {
  let x = description && JSON.parse(description);
  let descriptions = [];

  if (x) {
    for (let descriptionItem of x) {
      descriptions.push(descriptionItem.children[0].text);
    }
  }

  console.log(descriptions);

  return descriptions;
}

function YouTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

function ReadsDetailsSection(props) {
  const click = () => {
    console.log("Back Button Clicked");
  };

  const { id } = props;
  const history = useNavigate();

  const [read, setRead] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getNews();

        let data = response?.data?.body?.data ?? [];
        data = data.filter(({ _id }) => _id === id);

        let reads = data.map((item) => {
          let images = [];
          let additionalDescriptions = [];
          let videos = [];

          if (item.article?.additionalContent) {
            for (let content of item.article.additionalContent) {
              if (content.type === "text") {
                let descriptions = ParseDescriptions(content.value);
                for (const description of descriptions) {
                  additionalDescriptions.push(description);
                }
              } else if (content.type === "image") {
                images.push({
                  name: content.value.imageName,
                  image: content.value.imageUrl,
                });
              } else if (content.type === "video") {
                const videoID = YouTubeGetID(content.value);

                videos.push({
                  image:
                    videoID !== ""
                      ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
                      : ImageOne,
                  link: content.value,
                });
              }
            }
          }
          

            return {
              id: item._id,
              image: item.article.imageMetaData.imageThumbnail,
              title: item.article.title,
              descriptions: ParseDescriptions(item.article.description),
              additionalDescriptions: additionalDescriptions,
              images: images,
              videos: videos,
            };
        });

        console.log(reads[0]);

        setRead(reads[0]);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);

  const socialIconData = [
    {
      icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-facebook-icon.webp",
      link: "https://www.facebook.com/",
    },
    {
      icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-twitter-icon.webp",
      link: "https://www.facebook.com/",
    },
    {
      icon: "https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-manage-masjid-organization-youtube-icon.webp",
      link: "https://www.facebook.com/",
    },
  ];

  return (
    <div className="FeaturedEvents">
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="20px"
        Container={
          <>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <div className="Socialsection">
                  <div>
                    <Button
                      className="backBtn"
                      onClick={() =>
                        history.previous ? history.goBack() : history("/reads")
                      }
                      href="#"
                    >
                      <ArrowBackRoundedIcon /> All Reads
                    </Button>
                  </div>
                  <div>
                    <SocialMediaOne
                      copyBackground="#DB9E30"
                      copyColor="#fff"
                      socialIconData={socialIconData}
                      copyButton={true}
                      onClick={click}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="eventDetailsBanner">
                  <img src={read.image} alt="read-banner" />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={5} xs={12}>
                <div className="eventDetailsContetns">
                  <h1>{read.title}</h1>
                </div>
              </Grid>

              <Grid item md={7} xs={12}>
                <div className="eventDetailsContetns">
                  {read?.descriptions?.map((item, index) => {
                    return <p key={`pt-${index}`}>{item}</p>;
                  })}

                  {read?.additionalDescriptions?.map((item, index) => {
                    return <p key={`${index}${index}`}>{item}</p>;
                  })}

                  {read?.images?.map((item, index) => {
                    return (
                      <>
                        <br />
                        <Grid key={index} item md={12} xs={12}>
                          <div className="eventDetailsBanner">
                            <img src={item.image} alt={item.name} />
                          </div>
                        </Grid>
                      </>
                    );
                  })}

                  {read?.videos?.map((item, index) => {
                    return (
                      <>
                        <br />
                        <VideoCardOne
                          img={item.image}
                          ImgHeight="196px"
                          link={item.link}
                          border="border"
                          icon={<SubscriptionsRoundedIcon />}
                        />
                      </>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </>
        }
      />
    </div>
  );
}

export default ReadsDetailsSection;
