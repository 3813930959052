import { React, useState, useEffect } from "react";
import { ContactUsThree, Alert } from "master-react-lib";
import { postContactData } from "../backend.js";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import DoneImg from "../assets/imgs/check-mark.png";


function ContactUs() {
  const [showAlert, setShowAlert] = useState(false);

  const selectdata = [
    { value: 1, select: "General Queries" },
    { value: 2, select: "Services/Activities" },
    { value: 3, select: "Masjid Tour" },
    { value: 4, select: "Donation" },
    { value: 5, select: "Volunteer" },
    { value: 6, select: "Media/Press" },
  ];

  const onContactFormSubmit = (contactFormData) => {
    setShowAlert(true);

    //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    //alert("Message sent!");

    const response = postContactData({
      name: `${contactFormData.firstName} ${contactFormData.lastName}`,
      email: contactFormData.email,
      inquiryType: selectdata.find((item) => {
        return item.value === contactFormData.select;
      }).select,
      message: contactFormData.message,
      phone: contactFormData.phone,
      stream: "THE-ABRAR",
      orgId: process.env.REACT_APP_ORGANIZATION_ID,
    });
  };

  return (
    <>
      <div>
        <ContactUsThree
          onContactFormSubmit={onContactFormSubmit}
          selectdata={selectdata}
          ButtonLabel="SEND MESSAGE"
          ButtonColor="#56B149"
          phoneNumberError="error"
        />
      </div>
      {showAlert && (
        <Alert
          message="Message sent successfully."
          leftIcon={<CheckCircleOutlineRoundedIcon />}
          backgroundColor="#56B149"
          AlertDuration={8000}
          AlertWidth="500px"
          borderRadius="4px"
        />
      )}
    </>
  );
}

export default ContactUs;
