import { React, useState, useEffect } from "react";
import { FooterBottomOne } from "master-react-lib";

import Grid from '@material-ui/core/Grid';
import FacebookRounded from "../assets/imgs/socialicons/FacebookRounded.svg";
import YouTubeRounded from "../assets/imgs/socialicons/YouTubeRounded.svg";
import TwitterRounded from "../assets/imgs/socialicons/TwitterRounded.svg";
import WhatsappRounded from "../assets/imgs/socialicons/WhatsappRounded.svg";

import FbIcon from "../assets/imgs/socialicons/fb-icon.svg";
import youtubeIcon from "../assets/imgs/socialicons/youtube-icon.svg";

import callicon from "../assets/imgs/callicon.png";
import emailicon from "../assets/imgs/emailicon.png";

import { ButtonOne } from "master-react-lib";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


function Footer() {
  const click = () => {
    console.log("Back Button Clicked");
  };

  return (
    <div className="ExpansionFooterContainer">

      <div className="FooterContainer">

        <div className="FooterTop">

            <div className="FooterLogo">
              <img src={require('../assets/imgs/deenconnect-the-abrar-footer-logo.png')} alt="The Abrar Footer-logo" />
              <div className="Footercontent">
              <h1>The Abrar Masjid</h1>
              <h4>We are 501(c)(3) tax-exempted organization.</h4>
            </div>
            </div>



          <div className="FooterTopSocialicons">
            <div className="FooterSocialicons">
              <a href="https://www.facebook.com/theAbrarNYC/"><img src={FacebookRounded} /></a>
              <a href="https://www.youtube.com/channel/UCW31JSvDCszmIMcMvsiIKng/channels"><img src={YouTubeRounded} /></a>
              {/* <a href="#"><img src={TwitterRounded} /></a>
              <a href="#"><img src={WhatsappRounded} /></a> */}
            </div>
          </div>

        </div>
      </div>

      <div className="borderLine"></div>



      <div className="FooterContainer">
        <div className="FooterBody">

          <Grid container spacing={3}>

            <Grid item xs={12} sm={4} md={4}>

              <div className="FooterAdressContent">
                <h3>Address</h3>
                <p>3016 Bailey Ave (7,869.05 mi) </p>
                <p>Bronx, New York 10463</p>
                <div className="Getdirectionbtn">
                  <ButtonOne
                    label="GET DIRECTIONS"
                    onClick={click}
                    link="https://www.google.com/maps/search//@40.8768552,-73.9381665,13z/data=!4m16!1m12!4m11!1m6!1m2!1s0x89c2f331c2eaa0d1:0x38c0741604147d34!2sThe+Abrar+Masjid,+3016+Bailey+Ave,+The+Bronx,+NY+10463,+United+States!2m2!1d-73.9034744!2d40.8764526!1m3!2m2!1d-73.9580607!2d40.8921501!2m2!3m1!5e2"
                    target="_blank"
                    path="/"
                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={false}
                    addIcon={false}
                    handIconStatus={false}
                    buttoncolor="#DB9E30"
                    width="190px"
                    height="46px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#fff"
                    fontWeight="600"
                    textAlign="center"
                  //disabled="disabled"
                  />
                </div>
              </div>

            </Grid>

            <Grid item xs={12} sm={4} md={4}>

              <div className="FooterContactContent">
                <h3>Contact</h3>
                <div className="Contactdetails">
                  <div className="Calldetails">

                    <img src={callicon} />
                    <p>+1 347-326-5326 </p>
                  </div>

                  <div className="Emaildetails">
                    <img src={emailicon} />
                    <p>support@theabrar.org</p>
                  </div>

                </div>
                <div className="Getdirectionbtn">
                  <ButtonOne
                    label="Contact us"
                    onClick={click}
                    link="/contact-us"
                    //target="blank"
                    path="/"
                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={false}
                    addIcon={false}
                    handIconStatus={false}
                    buttoncolor="#DB9E30"
                    width="190px"
                    height="46px"
                    border="#ccc 0px solid"
                    borderRadius="4px"
                    color="#fff"
                    fontWeight="600"
                    textAlign="center"
                  //disabled="disabled"
                  />
                </div>
              </div>

            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <div className="Sociallinksdetails">
                <h3>Social</h3>

                <div className="FooterSocialIcon">
                  <a className="fbButton btn" href="https://www.facebook.com/theAbrarNYC/"> <img src={FbIcon} /> FOLLOW US ON FACEBOOK</a>
                  <a className="YoutubeButton btn" href="https://www.youtube.com/channel/UCW31JSvDCszmIMcMvsiIKng/channels"> <img src={youtubeIcon} /> WATCH OUR VIDEOS ON YOUTUBE</a>
                </div>
              </div>
            </Grid>

          </Grid>


        </div>
        <div className="borderLine"></div>
        <div className="Footerbottom">

          <div className="Footerbottomdetails">

            <span class="divider">

              <div className="RightCol">
                <p>TERMS OF SERVICES | PRIVACY POLICY</p>
              </div>

              <div className="LeftCol">
                <p>© 2022 THE ABRAR MASJID. ALL RIGHT RESERVED</p>
              </div>
            </span>
          </div>
        </div>


      </div>

      <FooterBottomOne bordercolor="#B9278A" Container="1170px" />
    </div>

  );
}

export default Footer;
