import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import { Section, AccordionOne, CTAOne } from "master-react-lib";
import CtaOneCardDark from './CtaOneCardDark';

import PageTitle from "./PageTitle";

function FaqSection() {

    const [expanded, setExpanded] = React.useState('1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const faqData = [

        {
            panel: "1",
            question: "What is Islam?",
            ans: <p><b>Islam is a faith of pure monotheism that builds on the messages of previous prophets sent to humankind throughout history.</b><br></br> <br></br>Islam is a continuation of the same message preached by all of God’s prophets — ending with the final prophet, Muhammad ﷺ — calling people to believe and worship in the one, true God alone. It is the original religion of Adam, Abraham, Moses, and Jesus that clarifies the purpose of creation and guides us on how to live a Godly, righteous life. <br></br> <br></br>As the final message from God to humanity, Islam is a manifestation of the Divine covenant made before the creation of the world where every soul gathered to recognize God as their Lord. In the Islamic worldview, this knowledge is coded into our subconscious makeup. Through testifying one’s faith, we reaffirm our witness of God’s Lordship and commit to living out a life according to and in line with His will. The Qur’an, God’s final words revealed to Prophet Muhammad, and prophetic teachings (Sunnah) offer guidance on how to adhere to the message.</p>,
        },

        {
            panel: "2",
            question: "What does Islam mean?",
            ans: <p>The word ‘Islam’ is an Arabic term encompassing the meanings of submission, surrender, and peace.</p>
        },

        {
            panel: "3",
            question: "What do Muslims believe?",
            ans: <p>In Islam, faith is composed of six core articles of faith, commonly referred to as the pillars of faith.

                They are:

                Belief in God
                Belief in angels
                Belief in Divinely revealed books
                Belief in the Messengers of God
                Belief in the Day of Judgement
                Belief in Divine Decree

            </p>
        },

        {
            panel: "4",
            question: "Who is Allah?",
            ans: <p>Say [O Prophet], “He is Allah—One. God, the eternal. He has never had offspring, nor was He born. And there is none comparable to Him.”

                (Qur’an 112: 1-4).<br></br>The word ‘Allah’ does not have a gender or a plural form, indicating God’s uniqueness from all creation and His absolute oneness. Allah is also the term for ‘God’ used by non-Muslim Arabic speakers such as Arab Christians.<br></br>
                <br></br>Muslims believe God is exalted above everything else in the universe, unrestricted by time and space, and distinct in His attributes. He is All-Powerful and All-Wise, with full control over the universe, as it was created by His will. As taught in the Qur’an, Allah is the Lord of all the Worlds; humanity’s Creator, Nourisher, and Sustainer, whom all should worship and love.
                <br></br>The 99 Beautiful Names of God encapsulate the various divine attributes through which God has described Himself. Allah is not restricted to these names alone; rather, they are a means by which someone can begin to know Him. God, above all else in the Islamic worldview, is the Most Merciful and the Most Kind (these are the names most often repeated in the Qur’an and in daily prayers), the Most Loving, and the Source of all peace, reliance, and refuge. The gift of worship is from His bounty to keep mankind forever connected to Him.
                <br></br><br></br><i>“He is God: there is no god other than Him. It is He who knows what is hidden as well as what is in the open, He is the Lord of Mercy, the Giver of Mercy. He is God: there is no god other than Him, the Controller, the Holy One, Source of Peace, Granter of Security, Guardian over all, the Almighty, the Compeller, the Truly Great; God is far above anything they consider to be His partner. He is God: the Creator, the Originator, the Shaper. The best names belong to Him. Everything in the heavens and earth glorifies Him: He is the Almighty, the Wise.” [Quran, 59:22-24]
                </i>
            </p>,
        },

        {
            panel: "5",
            question: "What is the Qur'an?",
            ans: <p><b>The Qur’an is the speech of God revealed to Prophet Muhammad ﷺ through the Angel Gabriel (Jibril) over a period of 23 years.</b>
                <br></br>
                The Qur’an constitutes the final revealed message from God through the last prophet and messenger sent to the world.<br></br>
                <br></br><b>The Qur’an is both miraculously preserved and inimitable.</b>In other words, what we know as the Qur’an today is the exact same text that was revealed to Prophet Muhammad ﷺ. The Qur’an is also miraculous in that it is impossible for human beings to imitate, contains no errors or contradictions, accurately foretells future events, describes matters of the natural world unknown to human beings at the time of revelation, and contains benefit for all human beings.
            </p>
        },

        {
            panel: "6",
            question: "Who is Prophet Muhammad?",
            ans: <p>Muhammad ﷺ, the son of Abdullah, was the final prophet and messenger of God.
                <br></br>He was born in 570 CE in Mecca, where he eventually received the final revealed book sent to humanity, the Qur’an, and began calling people to the religion of Islam.
                <br></br><br></br>
                Despite facing many challenges and being met with rejection and persecution during his life and prophetic mission, he unfailingly exhibited beautiful character, rose above enmity and insult, and accomplished a stunning array of achievements. He is the most beloved of all creation to God, and is the greatest of not only all of God’s prophets and messengers, but the entirety of creation.
                <br></br><br></br>
                Prophet Muhammad ﷺ is often described as the “founder of Islam,” but this is not exactly accurate. Islam did not begin with his prophetic mission. Instead, it is more accurate to say he was sent to confirm and restore the messages of previous prophets and scriptures in their original, uncorrupted forms. All of the messengers of God, from Adam to Jesus to Muhammad, conveyed the same fundamental message of monotheism and good works and are brothers in prophethood.
            </p>,
        },

        {
            panel: "7",
            question: "What are the five pillars of Islam?",
            ans: <p>The five pillars of Islam are: the testimony of faith, prayer, zakat, fasting of Ramadan, and performing hajj.
                <br></br>   <br></br>
                Islam can be imagined as a building supported by five pillars. Each of these pillars are necessary for the building of Islam to stand. Without them, the structure collapses. These pillars are:
                Shahada: Testifying that there is no god but Allah (God), and that Muhammad ﷺ is the final Messenger of God
                Salat: Praying five daily prayers
                Zakat: Donating an obligatory amount of charity
                Sawm: Fasting in Ramadan
                Hajj: Making the pilgrimage to the Ka’aba
                Everything else in Islam is built upon these five foundational pillars.
            </p>
        },

        {
            panel: "8",
            question: "What is the Islamic testimony of faith (shahada)?",
            ans: <p>The shahada is to believe in the message of Islam and declare that: There is no god but Allah (God), and Muhammad ﷺ is the Messenger of God.<br></br><br></br>
                This testimony of faith, known in Arabic as the shahada, encapsulates the religion of Islam. It must be affirmed in the heart and uttered by the tongue.<br></br><br></br>
                The shahada is a declaration of monotheism (tawhid) stating that there is absolutely no deity except for the one, true, creator: God. He has no partners in divinity, no children, and none are worthy of worship except Him.
                <br></br><br></br>
                It is also a statement of acceptance that Prophet Muhammad ﷺ is God’s final prophet and messenger.

            </p>
        },

        {
            panel: "9",
            question: "Why do Muslim pray daily?",
            ans: <p>Prayer (salah) forges and sustains an essential connection between a person and their Creator.Prayer (salah) forges and sustains an essential connection between a person and their Creator.
                <br></br><br></br>Muslims have been instructed by God to pray five times daily within prescribed times. The Qur’an informs us that the primary reason for our creation and existence is to worship God, and these required five daily prayers are, in essence, a foundational fulfillment of this existential purpose.</p>,
        },

        {
            panel: "10",
            question: "What is zakat?",
            ans: <p>Zakat refers to an obligatory portion of wealth a Muslim must give annually to the poor and needy.
                <br></br>Islamic law details the various types of wealth that are zakat-eligible, how it is calculated, and the specific categories of people eligible to receive it.
                <br></br>Zakat is frequently mentioned alongside prayer (salat) throughout the Qur’an and was a ubiquitous feature of all prophetic calls. Among the five pillars of Islam, zakat is unique in that, in addition to being an act of devotion to God, it plays an important social function. While things like prayer and fasting are, on their face, individual acts of ritual devotion, zakat directly benefits one’s community and, as an institution, wields immense economically and socially transformative power.
            </p>,
        },

        {
            panel: "4",
            question: "What is sadaqa?",
            ans: <p>Sadaqa refers to any act of charity or giving.
                  <br></br><br></br>
            Charity is one of the most highly emphasized acts and virtues in Islam and bears a great deal of spiritual and societal benefit. The widespread practice of Muslims establishing charitable endowments across Muslim societies is just one example of how this emphasis upon charitable giving manifests.
              
                <br></br>   <br></br>While the default conception of sadaqa is often monetary in nature, Islam outlines an expansive understanding of charity that extends to many non-monetary acts of giving such as spreading joy, meeting people with a smile, or merely speaking a kind word.
                <br></br> <br></br> In fact, Prophet Muhammad ﷺ said that, “Every good deed is charity. Verily, it is a good deed to meet your brother with a cheerful face, and to pour what is left from your bucket into the vessel of your brother.”
            </p>,
        },
        {
            panel: "11",
            question: "What holidays do Muslims celebrate?",
            ans: <p>Muslims celebrate two major holidays: Eid al-Fitr and Eid al-Adha.
                <br></br>Muslim days of celebration occur at the conclusion of seasons of worship. The day of Eid al-Fitr is celebrated at the end of Ramadan, while Eid al-Adha is celebrated at the conclusion of Hajj. These days are intended to be days of festivity and joy for Muslims, and are distinguished from other days since one is not allowed to fast on them.
                <br></br>
                Fridays are also weekly holy days for Muslims, as Prophet Muhammad ﷺ said, “Allah has made this day of Friday a celebration for the Muslims.”
            </p>,
        },
        {
            panel: "12",
            question: "What is Ramadan?",
            ans: <p>Ramadan is the 9th month of the Hijri, or Islamic lunar calendar, during which Muslims are obligated to fast each day of the entire month. <br></br><br></br>

                Fasting entails abstaining from food, drink, and intimacy from sunrise to sunset. All sane, mature Muslims are obligated to fast unless they have a legitimate excuse (due to travel, illness, etc.), in which case Islamic law details if and how they are to make up for missed Ramadan fasts. <br></br><br></br>

                The month of Ramadan is also the month in which the revelation of God’s final message to humanity, the Qur’an, began. The Qur’an was first revealed on the Night of Decree (laylat al-qadr), which is described as the greatest night of the entire year.</p>,
        },
        {
            panel: "13",
            question: "Why do Muslims have to fast for a full month?",
            ans: <p>Muslims fast for the entire month because God prescribed this to be the length of the fasting period.
                <br></br><br></br>
                During prayer five times a day, Muslims face the Ka’aba in Mecca. Rituals like this were prescribed by God and, thus, we follow them. Likewise, Muslims fast for the entire month because God prescribed that this be the length of the fast:
                <br></br><br></br>
                Ramadan is the month in which the Quran was revealed as a guide for humanity, with clear proofs of guidance and the standard ˹to distinguish between right and wrong˺. So whoever is present this month, let them fast…

                [Qur’an, 2:185]
                <br></br><br></br>
                The aim and purpose of fasting is to develop a greater sense of consciousness of God. Fasting also teaches Muslims self-restraint, and enables people to experience hunger and, hopefully, a greater sense of empathy for those who are less fortunate


            </p>
        },
        {
            panel: "14",
            question: "Do Muslims fast outside of Ramadan?",
            ans: <p>Muslims can voluntarily fast outside of the month of Ramadan, but are not required to do so.
                <br></br><br></br>
                Fasting outside of Ramadan is an extremely virtuous and highly rewarded practice that Muslims are encouraged to do if they can.
                <br></br><br></br>
                In fact, it was Prophet Muhammad’s ﷺ regular practice to fast on Mondays and Thursdays, three days at the middle of each lunar month, and a number of other days throughout the year.

            </p>,
        },
        {
            panel: "15",
            question: "What is the significance of the Hajj pilgrimage?",
            ans: <p>Hajj is one of the five pillars of Islam.
                <br></br><br></br>
                All Muslims must perform the Hajj once in a lifetime, if they are physically and financially able to do so. Among other things, the pilgrimage is a commemoration of the legacy and sacrifices of Prophet Abraham (Ibrahim), peace be upon him, and his family.
                <br></br><br></br>
                The rituals of Hajj are full of powerful symbolism and lessons, and experiencing them can bring about profound spiritual change.

            </p>,
        },
        {
            panel: "16",
            question: "Who is Jesus in Islam?",
            ans: <p>Jesus, the son of Mary, is considered a prophet and a messenger of God in Islam.
                <br></br><br></br>

                Muslims believe that Jesus (peace be upon him) was not only one of God’s prophets and messengers, but that he was among an elite category among them, distinguished for their extraordinary resolve and determination. This category includes Noah, Abraham, Moses, and Prophet Muhammad ﷺ.
                <br></br><br></br>
                Islam teaches that Jesus was born of a miraculous birth to the Virgin Mary (who is herself highly revered in Islam). He is the Messiah (al-Masih) and the Word and Spirit of God, who performed numerous miracles including healing the blind and lepers, feeding multitudes, and resurrecting the dead.

                <br></br><br></br>

                Muslims also believe in the Second Coming, and hold that Jesus will return to Earth shortly before the Day of Judgment to kill the False Messiah (al-Masih al-Dajjal, the Antichrist). He will rule the world justly, and usher in an era of unprecedented peace and prosperity.
                <br></br><br></br>
                Muslims further believe that Jesus enjoys a special connection and proximity to Prophet Muhammad ﷺ and his nation (i.e. Muslims): He will return to lead and pass away among Prophet Muhammad’s followers. Prophet Muhammad ﷺ also declared that he is “the nearest of people to Jesus,” that they both came with the same religion, and that, “there was no prophet [sent] between us.”
                <br></br><br></br>
                Muslims reject, however, that Jesus was crucified, that he is the son of God, and that he is a god himself</p>,
        },
        {
            panel: "17",
            question: "Do Muslims believe in the crucifixion of Jesus?",
            ans: <p>No, Muslims do not believe that Jesus was crucified or killed.
                <br></br><br></br>
                While Muslims do believe that Jesus and his followers were persecuted by authorities in his time, and that Jesus suffered betrayal at the hands of some of his own people, Islam teaches that Jesus was miraculously saved from crucifixion and ascended to the heavens where he remains alive.</p>,
        },
        {
            panel: "18",
            question: "Do Muslims believe in the Holy Spirit?",
            ans: <p>Muslims believe in the Holy Spirit (al-Ruh al-Qudus) with reference to the Angel Gabriel (Jibril) and not as a part of a trinitarian godhead.
                <br></br> <br></br>
                It was through the Holy Spirit that Jesus was able to perform numerous miracles.
                <br></br> <br></br>
                However, Muslims do not believe that the Holy Spirit is a part of a trinitarian godhead, nor a separate god to be worshiped or prayed to. Rather, the Holy Spirit, Gabriel, is a creation of God and under His complete control</p>,
        },
        {
            panel: "19",
            question: "Do Muslims believe in Heaven and Hell?",
            ans: <p>Yes. Belief in Heaven and Hell are essential aspects of Islamic belief.
                <br></br> <br></br>
                Islam teaches that, after death, all of humanity will eventually be resurrected on the Day of Judgement, judged according to their individual actions, and sent to Parardise or the Hellfire.
                <br></br> <br></br>
                Paradise is a place of eternal bliss, beauty, pleasure, and happiness, filled with delights that “no eye has ever seen, no ear has ever heard, and what no human mind has ever imagined.” It is a place free of any pain, suffering, sadness, or death.
                <br></br> <br></br>
                Hell is a terrifying place filled with pain, suffering, and misery. Some who enter it will eventually be taken out and placed in Heaven, while others will remain eternally.</p>,
        },
        {
            panel: "20",
            question: "Are there dietary restrictions in Islam?",
            ans: <p>Yes. Muslims are prohibited from consuming pork, alcohol, and substances that impair the mind, as well as those that do not meet proper standards (detailed below).
                <br></br> <br></br>
                While an exhaustive treatment of Islamic dietary rules is beyond our scope here, it suffices to say that Islam’s prohibition regarding food, drink, and substances extend beyond merely pork and alcohol.
                <br></br><br></br>
                For instance, Muslims are not permitted to use or ingest any type of intoxicating substance for merely recreational purposes. Additionally, certain types of animals (such as animals of prey) are not permitted for consumption. Even animals whose meat is permissible, if not slaughtered properly, becomes impermissible to consume.
            </p>,
        },
        {
            panel: "21",
            question: "What does halal mean?",
            ans: <p>Halal refers to that which is permissible to Muslims.
                <br></br><br></br>
                Halal means what is allowed, in contrast to what is referred to as haram, not allowed for Muslims. However, it is commonly used to refer to foods (especially meat) that Muslims are permitted to consume.
            </p>
        },
        {
            panel: "22",
            question: "Are kosher and halal the same?",
            ans: <p>There are many similarities between what is kosher (permissible for Jews to consume) and what is halal (permissible for Muslims to consume), but there are also significant differences.
                <br></br>  <br></br>
                The two dietary codes diverge on issues such as alcohol and food pairings. Kosher laws generally do not prohibit alcohol, whereas Islamic law strictly prohibits its consumption, even when used for cooking. Unlike Jews, Muslims do not require the separation of dairy and meat products.
                <br></br><br></br>

                However, both Islamic and Jewish dietary rules prohibit consuming pork and pork-derived products. They require similar ritual slaughtering procedures as well as the draining of the slaughtered animal’s blood, and both prohibit carrion (an animal found dead before slaughter). Many of the animals Muslims are allowed to slaughter are also permitted for Jews.
            </p>,
        },

    ];


    return (
        <div className='FaqSection'>

            <PageTitle
                Title="Frequently asked questions"
                Path="FAQ"
            />

            <Section
                background="#fff"
                //ContainerWidth=""
                Spacing="80px"
                Container={
                    <>
                        <Grid container spacing={3}>

                            <Grid item md={8} xs={12}>
                                {faqData.map((item, i) => (
                                    <AccordionOne
                                        title={item.question}
                                        panel={item.panel}
                                        expanded={expanded}
                                        handleChange={handleChange}

                                        ////// Styling ////////

                                        titleColor="#B9278A"
                                        titleFontSize="16px"
                                        TitleFontWeight="600"
                                        iconStatus={false}

                                        AccordionBody={
                                            <div>{item.ans}</div>

                                        }

                                    />
                                ))}


                                <div className="FaqSource">Source: <a href="https://yaqeeninstitute.org/what-islam-says-about/basics-of-islam" target="_blank">Yaqeen Institute</a></div>

                            </Grid>

                            <Grid item md={4} xs={12}>
                                <CtaOneCardDark />
                            </Grid>

                        </Grid>



                    </>
                }
            />



        </div>
    );
}


export default FaqSection;