import { React, useState, useEffect } from "react";
import { Section, CardSeven } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import ADS from "./ADS";

import { getEvents } from "../backend.js";
import moment from "moment";

import ImageOne from "../assets/imgs/events/web-service-event.jpg";
import ImageTwo from "../assets/imgs/events/events-1-1170x658.jpg";
import ImageThree from "../assets/imgs/events/events-4-1170x658.jpg";
import ImageFour from "../assets/imgs/events/events-6-1170x658.jpg";

function FeaturedEvents() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getEvents();

        let data = response?.data?.body?.data ?? [];

        data = data.filter(
          ({ status, featured, event, publishTo, publishFrom }) =>
            featured &&
            status === "Public" &&
            new Date(publishTo) > new Date() &&
            new Date(publishFrom) < new Date()
        );

        data = data.sort((a, b) => a.event.order - b.event.order);

        let events = data.map((item) => {
          let startDate = moment(item.event.eventStart.startAt)
            .utcOffset(0)
            .format("ddd, MMM DD");

          let startTime = moment(item.event.eventStart.startTime)
            .utcOffset(0)
            .format("h:mm a");

          return {
            id: item._id,
            image: item.event.imageDetails.imageUrl,
            title: item.event.title,
            subTitle: "Online Event",
            time: `${startDate} AT ${startTime} EST`.toUpperCase(),
            buttonText: "Event Details",
            link: `/events-details\\${item._id}`,
          };
        });

        setEvents(events);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    events.length > 0 ? (<div className="FeaturedEvents">
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="80px"
        Container={
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Title
                Title="Featured events"
                ButtonStatus={true}
                ButtonLabel="ALL EVENTS"
                Link="/events"
              />
            </Grid>

            <Grid item md={9} xs={12}>
              <Grid container spacing={3}>
                {events.map((event, index) => (
                  <Grid key={index} item md={6} xs={12}>
                    <CardSeven
                      image={event.image}
                      title={event.title}
                      subTitle={event.subTitle}
                      time={event.time}
                      buttonText={event.buttonText}
                      link={event.link}
                    />
                  </Grid>
                ))}

                {/* <Grid item md={6} xs={12}>
                  <CardSeven
                    image={ImageOne}
                    title="Web Service"
                    time="March 3, 2021 @ 8:00 am - 5:00 pm"
                    subTitle="welcome to our genius web service"
                    buttonText="Read More"
                    link="/events"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CardSeven
                    image={ImageTwo}
                    title="Message of the Quran Class"
                    time="June 5, 2023 @ 6:30 pm - 7:30 pm"
                    subTitle="We are updating.."
                    buttonText="Read More"
                    link="/events"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CardSeven
                    image={ImageThree}
                    title="Friday Prayer"
                    time="June 9, 2021 @ 1:00 pm - 2:00 pm"
                    subTitle="We are updating.."
                    buttonText="Read More"
                    link="/events"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <CardSeven
                    image={ImageFour}
                    title="Lecture On Prophets Salah"
                    time="May 31, 2023 @ 9:50 pm - 10:00 pm"
                    subTitle="We are updating.."
                    buttonText="Read More"
                    link="/events"
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item md={3} xs={12}>
              <ADS />
            </Grid>
          </Grid>
        }
      />
    </div>) : <></>
  );
}

export default FeaturedEvents;
